import React,{useState,useEffect} from 'react'
import "./Sidebar.css";
import { Link } from 'react-router-dom'
import main_logo from "../images/Groupeya_logo .png";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

const Sidebar = ({ sidebarOpen, closeSidebar }) => {

  const { addToast } = useToasts();
  const [activeTab, setActiveTab] = useState('');
  const location = useLocation();
  
  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.replace('/');
   
  };
  useEffect(() => {
    // Get the current path from the location object
    const currentPath = location.pathname;
    // Update the active tab based on the current path
    setActiveTab(currentPath);
  }, [location]);
  return (
    <div className={sidebarOpen ? "sidebar_responsive " : "border"} id="sidebar">
      <div className="sidebar__title flex justify-center items-center">
        <div className=' logo-container' >
         <Link  to="/"> <img src={main_logo} alt="logo" /></Link>
        </div>
        <i
          onClick={() => closeSidebar()}
          className="fa fa-times"
          id="sidebarIcon"
          aria-hidden="true"
        ></i>
      </div>
      <div className="sidebar__menu ">
        <div   className={activeTab === '/statistics'?"activeTab sidebar__link active_menu_link flex":"sidebar__link active_menu_link flex" }>
          <Link to="/statisti11" ><i className="fa fa-home flex"> <span className='px-2 font-bold ' >Dashboard</span> </i>
            </Link>
        </div>
        <h2 className="">Manage Riders</h2>
        <span className="flex flex-col px-3 sidebar__link">
          <span className={activeTab === '/create-rider' ? ' activeTab p-2':'p-2' }><Link to="/create-rider">Riders</Link></span>
          <span className={activeTab === '/assign-asset' ? ' activeTab p-2':'p-2' }> <Link to='/assign-asset' >Assign asset</Link></span>
        </span>
        <h2 className="">Manage Asset</h2>
        <span className="flex flex-col px-3 sidebar__link">
          <span className={activeTab === '/create-asset' ? ' activeTab p-2':'p-2' }> <Link to="/create-asset">Assets</Link></span>
        </span>
        <h2 className="">Payments</h2>
        <span className="flex flex-col px-3 sidebar__link">
          <span className={activeTab === '/rider-payments' ? ' activeTab p-2':'p-2' }> <Link to="/rider-payments">Rider Payments</Link></span>
        </span>
        <span className="flex flex-col px-3 sidebar__link">
          <span className={activeTab === '/payments-receipts' ? ' activeTab p-2':'p-2' }> <Link to="/payments-receipts">Receipts</Link></span>
        </span>
        <h2>  settings</h2>
        <div className="sidebar__link">
         
          <span className="flex flex-col">
          <span className={activeTab === '/user-profile' ? ' activeTab p-2':'p-2' }> <Link to='/user-profile' >User</Link></span>
            <span className="p-2">Roles</span>
          </span>
        </div>
        <div className="sidebar__logout border rounded-2xl cursor-pointer hover:bg-gray-400 hover:text-white  flex justify-around items-center" onClick={handleLogout}  >
          <i className="fa fa-power-off"></i>
          <span>Log out</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;