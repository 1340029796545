import React, { useEffect, useState } from 'react'
import DashboardLayout from "../DashboardLayout";
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import PopModal from "./PopModal";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import { fetchAsyncAsset, getAllAsset, getAllpaginatedAsset, getUser } from '../../../redux/transactions/UntappedSlice';

function CreateAsset() {
    const dispatch = useDispatch()
    const { addToast } = useToasts();
    const modalTitle = "Create Asset";
    const btn_name = 'Create';
    const modalEditTitle = "Update Asset";
    const edit_btn_name = 'Update';
    const modalviewTitle = "Asset";
    const view_btn_name = 'Moride';
    const [open, setOpen] = useState(false);
    const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD'); // Example: Set default date to the start of the current month
    const defaultEndDate = moment().format('YYYY-MM-DD'); // Set default end date to current date
    const [selectedRange, setSelectedRange] = useState([defaultStartDate, defaultEndDate]);
    const [searchQuery, setsearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const allAssetList = useSelector(getAllAsset);
    const allpaginatedAssets = useSelector(getAllpaginatedAsset)
    const isLoading = useSelector((state) => state.untapped.isLoading);
    const user_info = useSelector(getUser);
    const [editOpen, seteditOpen] = useState(false);
    const [editAsset, setEditAsset] = useState('');

    const [viewRider, setViewRider] = useState(false);
    const [viewRiderInfo, setViewRiderInfo] = useState(false);

    const handleDateRangeChange = (dates) => {
        if (dates) {
            const formattedDates = dates.map(dateObj => moment(dateObj.$d).format("YYYY-MM-DD"));
            setSelectedRange(formattedDates);
        }
    };
    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const handleSearch = (event) => {
        setsearchQuery(event.target.value)
    };

    const handleOpenModal = () => {
        setOpen(!open);
        setSerialNumberValue('');
        setMakeValue('');
        setModelValue('');
        setproductionYearValue('');
        setplateNumberValue('');
        setunitCostValue('');
        setinsuranceCostValue('');
        setdeploymentDateValue('');
    };
    const handleChildEvent = () => {
        setOpen(!open);
    };
    // form input 
    const [serialNumber, setSerialNumberValue] = useState('');
    const [make, setMakeValue] = useState('');
    const [model, setModelValue] = useState('');
    const [productionYear, setproductionYearValue] = useState('');
    const [plateNumber, setplateNumberValue] = useState('');
    const [unitCost, setunitCostValue] = useState('');
    const [insuranceCost, setinsuranceCostValue] = useState('');
    const [deploymentDate, setdeploymentDateValue] = useState('');
    const [assetStatus, setAssetStatusValue] = useState('');
    const [assetStatusList, setAssetStatusListValue] = useState([]);
    // errors state
    const [insurance_cost, setinsurance_cost] = useState('');
    const [makeError, setmakeError] = useState('');
    const [modelError, setmodelError] = useState('');
    const [production_year, setproduction_year] = useState('');
    const [serial_number, setserial_number] = useState('');
    const [unit_cost, setunit_cost] = useState('');
    const [plate_number, setplate_number] = useState('');

    const assetStatusHandleChange = (event) => {
        setAssetStatusValue(event.target.value);
    };

    const serialNumberHandleChange = (event) => {
        setSerialNumberValue(event.target.value);
    };
    const makeHandleChange = (event) => {
        setMakeValue(event.target.value);
    };
    const modelHandleChange = (event) => {
        setModelValue(event.target.value);
    };
    const productionYearHandleChange = (event) => {
        setproductionYearValue(event.target.value);
    };
    const plateNumberHandleChange = (event) => {
        setplateNumberValue(event.target.value);

    };
    const unitCostHandleChange = (event) => {
        setunitCostValue(event.target.value);
    };

    const insuranceCostHandleChange = (event) => {
        setinsuranceCostValue(event.target.value);
    };
    const deploymentDateHandleChange = (event) => {
        setdeploymentDateValue(event.target.value);
    };


    const handleChildEditEvent = () => {
        seteditOpen(!editOpen);
    };
    const handleEditAsset = async (asset) => {
        seteditOpen(!editOpen);
        setEditAsset(asset)
        fillEditForm(asset)
    };


    const handleSubmitAsset = async (event) => {

        const asset = {
            'serial_number': serialNumber,
            'make': make,
            'model': model,
            'production_year': productionYear,
            'plate_number': plateNumber,
            'unit_cost': unitCost,
            'insurance_cost': insuranceCost,
            'deployment_date': deploymentDate
        };
        // clearing errors state
        setinsurance_cost('');
        setmakeError('');
        setmodelError('');
        setproduction_year('');
        setserial_number('');
        setunit_cost('');
        setplate_number('');

        try {

            await axios.post('https://morideapi.groupeya.com/api/v1/assets/',

                asset,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Accept': 'application/json',
                        'Authorization': `Bearer ${user_info.access}`
                    },
                });
            addToast(`successful created`, { appearance: 'success' , autoDismiss: true, // Enable auto dismissal
            autoDismissTimeout: 5000,
            transitionDuration: 300,});
            dispatch(fetchAsyncAsset(user_info));
            setSerialNumberValue('');
            setMakeValue('');
            setModelValue('');
            setproductionYearValue('');
            setplateNumberValue('');
            setunitCostValue('');
            setinsuranceCostValue('');
            setdeploymentDateValue('');

        } catch (error) {
            if (error.response.status == 400) {
                // Request was successful

                let riderErros = Object.keys(error.response.data);
                ErrorHandler(riderErros);
                addToast('please fill the required field', { appearance: 'error' , autoDismiss: true, // Enable auto dismissal
                autoDismissTimeout: 5000,
                transitionDuration: 300,});

            } else {
                addToast(error.response.statusText, { appearance: 'error', autoDismiss: true, // Enable auto dismissal
                autoDismissTimeout: 5000,
                transitionDuration: 300, });
            }

        }
    };

    
    const customStyle = {
        marginLeft: -28,
        // other CSS properties can be added here
    };
    

    const handleUpdateAsset = async () => {
        const asset = {
            'serial_number': serialNumber,
            'make': make,
            'model': model,
            'production_year': productionYear,
            'plate_number': plateNumber,
            'unit_cost': unitCost,
            'insurance_cost': insuranceCost,
            'deployment_date': deploymentDate,
            'asset_status': assetStatus,
        };

        // clearing errors state
        setSerialNumberValue('');
        setMakeValue('');
        setModelValue('');
        setproductionYearValue('');
        setplateNumberValue('');
        setunitCostValue('');
        setinsuranceCostValue('');
        setdeploymentDateValue('');


        try {

            await axios.put(`https://morideapi.groupeya.com/api/v1/assets/${editAsset.id}/`,

                asset,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${user_info.access}`
                    },
                });
            dispatch(fetchAsyncAsset({ user_info, searchQuery, currentPage }));
            addToast(`successful updated`, {
                appearance: 'success', autoDismiss: true, // Enable auto dismissal
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });
            dispatch(fetchAsyncAsset(user_info));
            setSerialNumberValue('');
            setMakeValue('');
            setModelValue('');
            setproductionYearValue('');
            setplateNumberValue('');
            setunitCostValue('');
            setinsuranceCostValue('');
            setdeploymentDateValue('');

        } catch (error) {
            if (error.response.status == 400) {
                // Request was successful

                let riderErros = Object.keys(error.response.data);
                ErrorHandler(riderErros);
                addToast('please fill the required field', {
                    appearance: 'error', autoDismiss: true, // Enable auto dismissal
                    autoDismissTimeout: 5000,
                    transitionDuration: 300,
                });

            } else {
                addToast(error.response.statusText, {
                    appearance: 'error', autoDismiss: true, // Enable auto dismissal
                    autoDismissTimeout: 5000,
                    transitionDuration: 300,
                });
            }

        }

    }

    // delete asset
    const handleDeleteAsset = async (asset) => {
        const confirmed = window.confirm('Are you sure you want to delete this item?');
        if (confirmed) {

            try {
                await axios.delete(`https://morideapi.groupeya.com/api/v1/assets/${asset.id}/`, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${user_info.access}`
                    },
                });
                dispatch(fetchAsyncAsset({ user_info, searchQuery, currentPage }));
                addToast(`deleted`, {
                    appearance: 'success', autoDismiss: true, // Enable auto dismissal
                    autoDismissTimeout: 5000,
                    transitionDuration: 300,
                });
            } catch (error) {
                addToast(error.response.statusText, {
                    appearance: 'error', autoDismiss: true, // Enable auto dismissal
                    autoDismissTimeout: 5000,
                    transitionDuration: 300,
                });
            }
        }
    };
    const ErrorHandler = (fields) => {
        fields.map((field) => {
            if (field === "insurance_cost") {
                setinsurance_cost("insurance cost is required.");
            }
            if (field === "make") {
                setmakeError("This field may not be blank.");
            }
            if (field === "model") {
                setmodelError("A valid number is required");
            }
            if (field === "production_year") {
                setproduction_year("production year is required");
            }
            if (field === "serial_number") {
                setserial_number("A valid number is required");
            }
            if (field === "unit_cost") {
                setunit_cost("unit cost is required.");
            }
            if (field === "plate_number") {
                setplate_number("plate number is required");
            } else {
                return true;
            }
        });
    }

    const fillEditForm = (editAsset) => {
        setSerialNumberValue(editAsset.serial_number);
        setMakeValue(editAsset.make);
        setModelValue(editAsset.model);
        setproductionYearValue(editAsset.production_year);
        setplateNumberValue(editAsset.plate_number);
        setunitCostValue(editAsset.unit_cost);
        setinsuranceCostValue(editAsset.insurance_cost);
        setAssetStatusValue(editAsset.asset_status)
        setdeploymentDateValue(moment(editAsset.deployment_date).format('YYYY-MM-DD'));
    }
    const handleViewRider = (rider) => {
        setViewRiderInfo(rider)
        setViewRider(!viewRider);

    };
    const handleChildviewEvent = () => {

        setViewRider(!viewRider);

    };
    // delete asset
    const getAssetStatus = async () => {
        try {
            const response = await axios.get(`https://morideapi.groupeya.com/api/v1/assets/status`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user_info.access}`
                },
            });
            setAssetStatusListValue(response.data.results)

        } catch (error) {

        }
    };


    useEffect(() => {
        getAssetStatus();
        dispatch(fetchAsyncAsset({ user_info, searchQuery, currentPage }));

    }, [dispatch, user_info, searchQuery, currentPage,]);

    return (
        <DashboardLayout>
            <div className='flex justify-center items-center' >
                <div className='bg-white my-4 rounded-lg border container ' >
                    <div className='border-b  flex justify-between items-center   mx-3    px-3 py-4' >
                        <span>Assets</span>

                        <PopModal setOpenModal={open} onChildEvent={handleChildEvent} onChildSaveEvent={handleSubmitAsset} Title={modalTitle} button={btn_name} >
                            <div className='flex flex-col' >
                                <div className='form_control flex justify-between ' >
                                    <label className="block w-1/2  ">
                                        <span className="block text-sm font-medium text-slate-700">Serial number</span>
                                        <input type="text" className="peer ..." placeholder='Serial number' value={serialNumber} onChange={serialNumberHandleChange} />
                                        {serial_number && <p className=" mt-2 text-pink-600 text-sm">
                                            {serial_number}
                                        </p>}
                                    </label>
                                    <label className="block w-1/2 mx-2 ">
                                        <span className="block text-sm font-medium text-slate-700">Make</span>
                                        <input type="text" className="peer ..." placeholder='Make' value={make} onChange={makeHandleChange} />
                                        {makeError && <p className="mt-2  text-pink-600 text-sm">
                                            {makeError}
                                        </p>}
                                    </label>
                                    <label className="block w-1/2 ">
                                        <span className="block text-sm font-medium text-slate-700">Model</span>
                                        <input type="text" className="peer ..." placeholder='Model' value={model} onChange={modelHandleChange} />
                                        {modelError && <p className="mt-2 text-pink-600 text-sm">
                                            {modelError}
                                        </p>}
                                    </label>
                                </div>
                                <div className='form_control flex justify-between ' >
                                    <label className="block w-1/2  ">
                                        <span className="block text-sm font-medium text-slate-700">Production year</span>
                                        <input type="text" className="peer ..." placeholder='production year' value={productionYear} onChange={productionYearHandleChange} />
                                        {production_year && <p className="  text-pink-600 text-sm">
                                            {production_year}
                                        </p>}
                                    </label>
                                    <label className="block w-1/2 mx-2 ">
                                        <span className="block text-sm font-medium text-slate-700">Plate number</span>
                                        <input type="text" className="peer ..." placeholder='Plate number' value={plateNumber} onChange={plateNumberHandleChange} />
                                        {plate_number && <p className=" mt-2 text-pink-600 text-sm">
                                            {plate_number}
                                        </p>}
                                    </label>
                                    <label className="block w-1/2">
                                        <span className="block text-sm font-medium text-slate-700">Unit cost</span>
                                        <input type="text" className="peer ..." placeholder='Unit cost' value={unitCost} onChange={unitCostHandleChange} />
                                        {unit_cost && <p className=" mt-2 text-pink-600 text-sm">
                                            {unit_cost}
                                        </p>}
                                    </label>
                                </div>
                                <div className='form_control flex justify-start ' >
                                    <label className="block w-1/2  mr-2 ">
                                        <span className="block text-sm font-medium text-slate-700">Insurance Cost</span>
                                        <input type="text" className="peer ..." placeholder='Insurance Cost' value={insuranceCost} onChange={insuranceCostHandleChange} />
                                        {insurance_cost && <p className=" mt-2 text-pink-600 text-sm">
                                            {insurance_cost}
                                        </p>}
                                    </label>
                                    <label className="block  w-1/2">
                                        <span className="block text-sm font-medium text-slate-700">Deployment date</span>
                                        <input type="date" className="peer ..." placeholder='deployment date' value={deploymentDate} onChange={deploymentDateHandleChange} />
                                        <p className="mt-2 invisible peer-invalid:visible text-pink-600 text-sm">
                                            Please provide a valid last name
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </PopModal>
                        <span>
                            <button className='create_btn px-3 py-2 rounded-lg ' onClick={handleOpenModal}>Create asset</button>
                        </span>
                    </div>
                    <div className='   px-3 py-3' >

                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <div className="flex items-center justify-between pb-4">
                                <div>
                                    {/* <DatePicker.RangePicker onChange={handleDateRangeChange} defaultValue={[dayjs(defaultStartDate), dayjs(defaultEndDate)]}
                                        format="YYYY-MM-DD" /> */}
                                </div>
                                <label for="table-search" className="sr-only">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    </div>
                                    <input type="text" id="table-search" value={searchQuery} onChange={handleSearch} className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80  focus:ring-blue-500 focus:border-blue-500  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by plate" />
                                </div>
                            </div>
                            {allAssetList.length ? (<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
                                <thead className="text-xs text-gray-700 uppercase dark:text-gray-400 border-b">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            serialnumber
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            plate number
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            make/model
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            rider
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            unit cost
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            asset status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allAssetList.length && (allAssetList.map((asset, index) => (
                                        <tr className="bg-white border-b dark:hover:bg-gray-300 dark:hover:text-black" key={index} >
                                            <td className="w-4 p-4">
                                                <div className="flex items-center">
                                                    <span className='pl-1 flex justify-between items-center'>{asset.serial_number ? asset.serial_number : 'N/A'} </span>
                                                </div>
                                            </td>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                {asset.plate_number ? asset.plate_number : 'N/A'}
                                            </th>
                                            <td className="px-6 py-4">
                                                {asset.make ? asset.make : 'N/A'}/{asset.model ? asset.model : 'N/A'}
                                            </td>
                                            <td className="px-6 py-4">
                                                {asset.rider ? (<span>{asset.rider.first_name}{asset.rider.middle_name} {asset.rider.last_name}</span>) : ('N/A')
                                                }
                                            </td>

                                            <td className="px-6 py-4">
                                                {asset.unit_cost ? asset.unit_cost : 'N/A'}
                                            </td>
                                            <td className="px-6 py-4">
                                                {asset.asset_status === 1 && <span className="active"> {asset.status ? asset.status.name : 'N/A'}</span>}
                                                {asset.asset_status === 2 && <span className="Inactive"> {asset.status ? asset.status.name : 'N/A'}</span>}
                                            </td>

                                            <td className='px-6 py-4 flex justify-between' >
                                                <FontAwesomeIcon icon={faEdit} size="1x" className='cursor-pointer ' onClick={() => handleEditAsset(asset)} />
                                                <FontAwesomeIcon icon={faUsersViewfinder} size="1x" className='cursor-pointer text-gray-900 mx-5' onClick={() => handleViewRider(asset)} />
                                                <FontAwesomeIcon icon={faTrash} size="1x" className='cursor-pointer text-gray-900 ' onClick={() => handleDeleteAsset(asset)} />
                                                <PopModal onChildEvent={handleChildviewEvent} setOpenModal={viewRider} Title={modalviewTitle} button={view_btn_name}  >
                                                    <div className='flex flex-col border p-3 rounded-lg bg-slate-50' >
                                                        <div className='form_control flex justify-between ' >
                                                            <label className="block w-1/2  ">
                                                                <span className="block text-sm font-medium text-slate-700">Serial number</span>

                                                                {viewRiderInfo && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {viewRiderInfo.serial_number}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2 mx-2 ">
                                                                <span className="block text-sm font-medium text-slate-700">Make</span>

                                                                {viewRiderInfo && <p className="mt-2  text-pink-600 text-sm">
                                                                    {viewRiderInfo.make}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2 ">
                                                                <span className="block text-sm font-medium text-slate-700">Model</span>

                                                                {viewRiderInfo && <p className="mt-2 text-pink-600 text-sm">
                                                                    {viewRiderInfo.model}
                                                                </p>}
                                                            </label>
                                                        </div>
                                                        <div className='form_control flex justify-between ' >
                                                            <label className="block w-1/2  ">
                                                                <span className="block text-sm font-medium text-slate-700">Production year</span>

                                                                {viewRiderInfo && <p className="  text-pink-600 text-sm">
                                                                    {viewRiderInfo.production_year}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2 mx-2 ">
                                                                <span className="block text-sm font-medium text-slate-700">Plate number</span>

                                                                {viewRiderInfo && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {viewRiderInfo.plate_number}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2">
                                                                <span className="block text-sm font-medium text-slate-700">Unit cost</span>

                                                                {viewRiderInfo && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {viewRiderInfo.unit_cost}
                                                                </p>}
                                                            </label>
                                                        </div>
                                                        <div className='form_control flex justify-between ' >
                                                            <label className="block  w-1/2  ">
                                                                <span className="block text-sm font-medium text-slate-700">Insurance Cost</span>

                                                                {viewRiderInfo && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {viewRiderInfo.insurance_cost}
                                                                </p>}
                                                            </label>
                                                            <label className="block  w-1/2" style={customStyle} >
                                                                <span className="block text-sm font-medium text-slate-700">status</span>

                                                                {viewRiderInfo && <p className="mt-2   text-sm">
                                                                    {viewRiderInfo.asset_status === 1 && <span className="active"> {viewRiderInfo.status ? viewRiderInfo.status.name : 'N/A'}</span>}
                                                                    {viewRiderInfo.asset_status === 2 && <span className="Inactive"> {viewRiderInfo.status ? viewRiderInfo.status.name : 'N/A'}</span>}
                                                                </p>}
                                                            </label>
                                                            <label className="block  w-1/2" style={customStyle} >
                                                                <span className="block text-sm font-medium text-slate-700">Deployment date</span>

                                                                {viewRiderInfo && <p className="mt-2  text-pink-600 text-sm">
                                                                    {viewRiderInfo.deployment_date
                                                                        ? moment(viewRiderInfo.deployment_date).format('LLLL')
                                                                        : 'N/A'}
                                                                </p>}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </PopModal>

                                                <PopModal setOpenModal={editOpen} onChildEvent={handleChildEditEvent} onChildSaveEvent={handleUpdateAsset} Title={modalEditTitle} button={edit_btn_name} >
                                                    <div className='flex flex-col' >
                                                        <div className='form_control flex justify-between ' >
                                                            <label className="block w-1/2  ">
                                                                <span className="block text-sm font-medium text-slate-700">Serial number</span>
                                                                <input type="text" className="peer ..." value={serialNumber} onChange={serialNumberHandleChange} placeholder='Serial number' />
                                                                {serial_number && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {serial_number}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2 mx-2 ">
                                                                <span className="block text-sm font-medium text-slate-700">Make</span>
                                                                <input type="text" className="peer ..." placeholder='Make' value={make} onChange={makeHandleChange} />
                                                                {makeError && <p className="mt-2  text-pink-600 text-sm">
                                                                    {makeError}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2 ">
                                                                <span className="block text-sm font-medium text-slate-700">Model</span>
                                                                <input type="text" className="peer ..." placeholder='Model' value={model} onChange={modelHandleChange} />
                                                                {modelError && <p className="mt-2 text-pink-600 text-sm">
                                                                    {modelError}
                                                                </p>}
                                                            </label>
                                                        </div>
                                                        <div className='form_control flex justify-between ' >
                                                            <label className="block w-1/2  ">
                                                                <span className="block text-sm font-medium text-slate-700">Production year</span>
                                                                <input type="text" className="peer ..." placeholder='production year' value={productionYear} onChange={productionYearHandleChange} />
                                                                {production_year && <p className="  text-pink-600 text-sm">
                                                                    {production_year}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2 mx-2 ">
                                                                <span className="block text-sm font-medium text-slate-700">Plate number</span>
                                                                <input type="text" className="peer ..." placeholder='Plate number' value={plateNumber} onChange={plateNumberHandleChange} />
                                                                {plate_number && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {plate_number}
                                                                </p>}
                                                            </label>
                                                            <label className="block w-1/2">
                                                                <span className="block text-sm font-medium text-slate-700">Unit cost</span>
                                                                <input type="text" className="peer ..." placeholder='Unit cost' value={unitCost} onChange={unitCostHandleChange} />
                                                                {unit_cost && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {unit_cost}
                                                                </p>}
                                                            </label>
                                                        </div>
                                                        <div className='form_control flex justify-start items-center ' >
                                                            <label className="block w-1/2  mr-2 ">
                                                                <span className="block text-sm font-medium text-slate-700">Insurance Cost</span>
                                                                <input type="text" className="peer ..." placeholder='Insurance Cost' value={insuranceCost} onChange={insuranceCostHandleChange} />
                                                                {insurance_cost && <p className=" mt-2 text-pink-600 text-sm">
                                                                    {insurance_cost}
                                                                </p>}
                                                            </label>
                                                            <label class="block w-1/2 mr-2 ">
                                                                <span class="block text-sm font-medium text-slate-700"> Status</span>
                                                                <select className='border w-full' value={assetStatus} onChange={assetStatusHandleChange}>
                                                                    {assetStatusList.map((status) => (
                                                                        <option key={status.id} value={status.id}>
                                                                            {status.name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </label>
                                                            <label className="block  w-1/2">
                                                                <span className="block text-sm font-medium text-slate-700">Deployment date</span>
                                                                <input type="date" className="peer ..." placeholder='deployment date' value={deploymentDate} onChange={deploymentDateHandleChange} />

                                                            </label>
                                                        </div>
                                                    </div>
                                                </PopModal>
                                            </td>
                                        </tr>
                                    )))
                                    }
                                </tbody>
                            </table>) : (isLoading ? (<div role="status" className='flex justify-center my-5' >
                                <svg aria-hidden="true" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>) : (<div className='flex justify-center m-5 p-4'>
                                No Asset
                            </div>))}
                        </div>
                        <div className='flex justify-end my-3' >
                            <Pagination defaultCurrent={1} total={allpaginatedAssets.count} onChange={handlePageChange} className="border p-3 rounded-lg" />
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default CreateAsset
