import React, { useEffect, useState, useRef } from 'react'
import DashboardLayout from "../DashboardLayout"
import moment from 'moment';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import PopModal from "./PopModal";
import upload from "../../images/upload.svg";
import Select from 'react-select';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { fetchAsyncRiders, getAllpaginatedRiders, getAllRiders, getUser } from '../../../redux/transactions/UntappedSlice';

function FuelEssance({ onChildEvent }) {

  const myElementRef = useRef(null);
  const { addToast } = useToasts();
  const [open, setOpen] = useState(false);
  const [editOpen, seteditOpen] = useState(false);
  const [viewRider, setViewRider] = useState(false);
  const [viewRiderInfo, setViewRiderInfo] = useState(false);
  const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD'); // Example: Set default date to the start of the current month
  const defaultEndDate = moment().format('YYYY-MM-DD'); // Set default end date to current date
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setsearchQuery] = useState('');
  const [idValidations, setIdValidations] = useState(false);
  const [phoneValidations, setPhoneValidations] = useState(false);

  const [EditInfo, setEditInfo] = useState('');
  const [editRider, setEditRider] = useState({
    has_health_insurance: true,
    Marital_status: 'S', // Assuming true or false based on the initial state
  });


  const modalTitle = 'Update Rider';
  const btn_name = "update";
  const modalRiderTitle = ' Rider profile';
  const Moridebtn_name = "Moride";
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const maxDate = moment().format('YYYY');;


  // new
  const options = [
    { "code": "RW", "label": "Rwanda", "number": "646" },
    { "code": "AF", "label": "Afghanistan", "number": "004" },
    { "code": "AL", "label": "Albania", "number": "008" },
    { "code": "DZ", "label": "Algeria", "number": "012" },
    { "code": "AS", "label": "American Samoa", "number": "016" },
    { "code": "AD", "label": "Andorra", "number": "020" },
    { "code": "AO", "label": "Angola", "number": "024" },
    { "code": "AI", "label": "Anguilla", "number": "660" },
    { "code": "AQ", "label": "Antarctica", "number": "010" },
    { "code": "AG", "label": "Antigua and Barbuda", "number": "028" },
    { "code": "AR", "label": "Argentina", "number": "032" },
    { "code": "AM", "label": "Armenia", "number": "051" },
    { "code": "AW", "label": "Aruba", "number": "533" },
    { "code": "AU", "label": "Australia", "number": "036" },
    { "code": "AT", "label": "Austria", "number": "040" },
    { "code": "AZ", "label": "Azerbaijan", "number": "031" },
    { "code": "BS", "label": "Bahamas (the)", "number": "044" },
    { "code": "BH", "label": "Bahrain", "number": "048" },
    { "code": "BD", "label": "Bangladesh", "number": "050" },
    { "code": "BB", "label": "Barbados", "number": "052" },
    { "code": "BY", "label": "Belarus", "number": "112" },
    { "code": "BE", "label": "Belgium", "number": "056" },
    { "code": "BZ", "label": "Belize", "number": "084" },
    { "code": "BJ", "label": "Benin", "number": "204" },
    { "code": "BM", "label": "Bermuda", "number": "060" },
    { "code": "BT", "label": "Bhutan", "number": "064" },
    { "code": "BO", "label": "Bolivia (Plurinational State of)", "number": "068" },
    { "code": "BQ", "label": "Bonaire, Sint Eustatius and Saba", "number": "535" },
    { "code": "BA", "label": "Bosnia and Herzegovina", "number": "070" },
    { "code": "BW", "label": "Botswana", "number": "072" },
    { "code": "BV", "label": "Bouvet Island", "number": "074" },
    { "code": "BR", "label": "Brazil", "number": "076" },
    { "code": "IO", "label": "British Indian Ocean Territory (the)", "number": "086" },
    { "code": "BN", "label": "Brunei Darussalam", "number": "096" },
    { "code": "BG", "label": "Bulgaria", "number": "100" },
    { "code": "BF", "label": "Burkina Faso", "number": "854" },
    { "code": "BI", "label": "Burundi", "number": "108" },
    { "code": "CV", "label": "Cabo Verde", "number": "132" },
    { "code": "KH", "label": "Cambodia", "number": "116" },
    { "code": "CM", "label": "Cameroon", "number": "120" },
    { "code": "CA", "label": "Canada", "number": "124" },
    { "code": "KY", "label": "Cayman Islands (the)", "number": "136" },
    { "code": "CF", "label": "Central African Republic (the)", "number": "140" },
    { "code": "TD", "label": "Chad", "number": "148" },
    { "code": "CL", "label": "Chile", "number": "152" },
    { "code": "CN", "label": "China", "number": "156" },
    { "code": "CX", "label": "Christmas Island", "number": "162" },
    { "code": "CC", "label": "Cocos (Keeling) Islands (the)", "number": "166" },
    { "code": "CO", "label": "Colombia", "number": "170" },
    { "code": "KM", "label": "Comoros (the)", "number": "174" },
    { "code": "CD", "label": "Congo (the Democratic Republic of the)", "number": "180" },
    { "code": "CG", "label": "Congo (the)", "number": "178" },
    { "code": "CK", "label": "Cook Islands (the)", "number": "184" },
    { "code": "CR", "label": "Costa Rica", "number": "188" },
    { "code": "HR", "label": "Croatia", "number": "191" },
    { "code": "CU", "label": "Cuba", "number": "192" },
    { "code": "CW", "label": "Curaçao", "number": "531" },
    { "code": "CY", "label": "Cyprus", "number": "196" },
    { "code": "CZ", "label": "Czechia", "number": "203" },
    { "code": "CI", "label": "Côte d'Ivoire", "number": "384" },
    { "code": "DK", "label": "Denmark", "number": "208" },
    { "code": "DJ", "label": "Djibouti", "number": "262" },
    { "code": "DM", "label": "Dominica", "number": "212" },
    { "code": "DO", "label": "Dominican Republic (the)", "number": "214" },
    { "code": "EC", "label": "Ecuador", "number": "218" },
    { "code": "EG", "label": "Egypt", "number": "818" },
    { "code": "SV", "label": "El Salvador", "number": "222" },
    { "code": "GQ", "label": "Equatorial Guinea", "number": "226" },
    { "code": "ER", "label": "Eritrea", "number": "232" },
    { "code": "EE", "label": "Estonia", "number": "233" },
    { "code": "SZ", "label": "Eswatini", "number": "748" },
    { "code": "ET", "label": "Ethiopia", "number": "231" },
    { "code": "FK", "label": "Falkland Islands (the) [Malvinas]", "number": "238" },
    { "code": "FO", "label": "Faroe Islands (the)", "number": "234" },
    { "code": "FJ", "label": "Fiji", "number": "242" },
    { "code": "FI", "label": "Finland", "number": "246" },
    { "code": "FR", "label": "France", "number": "250" },
    { "code": "GF", "label": "French Guiana", "number": "254" },
    { "code": "PF", "label": "French Polynesia", "number": "258" },
    { "code": "TF", "label": "French Southern Territories (the)", "number": "260" },
    { "code": "GA", "label": "Gabon", "number": "266" },
    { "code": "GM", "label": "Gambia (the)", "number": "270" },
    { "code": "GE", "label": "Georgia", "number": "268" },
    { "code": "DE", "label": "Germany", "number": "276" },
    { "code": "GH", "label": "Ghana", "number": "288" },
    { "code": "GI", "label": "Gibraltar", "number": "292" },
    { "code": "GR", "label": "Greece", "number": "300" },
    { "code": "GL", "label": "Greenland", "number": "304" },
    { "code": "GD", "label": "Grenada", "number": "308" },
    { "code": "GP", "label": "Guadeloupe", "number": "312" },
    { "code": "GU", "label": "Guam", "number": "316" },
    { "code": "GT", "label": "Guatemala", "number": "320" },
    { "code": "GG", "label": "Guernsey", "number": "831" },
    { "code": "GN", "label": "Guinea", "number": "324" },
    { "code": "GW", "label": "Guinea-Bissau", "number": "624" },
    { "code": "GY", "label": "Guyana", "number": "328" },
    { "code": "HT", "label": "Haiti", "number": "332" },
    { "code": "HM", "label": "Heard Island and McDonald Islands", "number": "334" },
    { "code": "VA", "label": "Holy See (the)", "number": "336" },
    { "code": "HN", "label": "Honduras", "number": "340" },
    { "code": "HK", "label": "Hong Kong", "number": "344" },
    { "code": "HU", "label": "Hungary", "number": "348" },
    { "code": "IS", "label": "Iceland", "number": "352" },
    { "code": "IN", "label": "India", "number": "356" },
    { "code": "ID", "label": "Indonesia", "number": "360" },
    { "code": "IR", "label": "Iran (Islamic Republic of)", "number": "364" },
    { "code": "IQ", "label": "Iraq", "number": "368" },
    { "code": "IE", "label": "Ireland", "number": "372" },
    { "code": "IM", "label": "Isle of Man", "number": "833" },
    { "code": "IL", "label": "Israel", "number": "376" },
    { "code": "IT", "label": "Italy", "number": "380" },
    { "code": "JM", "label": "Jamaica", "number": "388" },
    { "code": "JP", "label": "Japan", "number": "392" },
    { "code": "JE", "label": "Jersey", "number": "832" },
    { "code": "JO", "label": "Jordan", "number": "400" },
    { "code": "KZ", "label": "Kazakhstan", "number": "398" },
    { "code": "KE", "label": "Kenya", "number": "404" },
    { "code": "KI", "label": "Kiribati", "number": "296" },
    { "code": "KP", "label": "Korea (the Democratic People's Republic of)", "number": "408" },
    { "code": "KR", "label": "Korea (the Republic of)", "number": "410" },
    { "code": "KW", "label": "Kuwait", "number": "414" },
    { "code": "KG", "label": "Kyrgyzstan", "number": "417" },
    { "code": "LA", "label": "Lao People's Democratic Republic (the)", "number": "418" },
    { "code": "LV", "label": "Latvia", "number": "428" },
    { "code": "LB", "label": "Lebanon", "number": "422" },
    { "code": "LS", "label": "Lesotho", "number": "426" },
    { "code": "LR", "label": "Liberia", "number": "430" },
    { "code": "LY", "label": "Libya", "number": "434" },
    { "code": "LI", "label": "Liechtenstein", "number": "438" },
    { "code": "LT", "label": "Lithuania", "number": "440" },
    { "code": "LU", "label": "Luxembourg", "number": "442" },
    { "code": "MO", "label": "Macao", "number": "446" },
    { "code": "MG", "label": "Madagascar", "number": "450" },
    { "code": "MW", "label": "Malawi", "number": "454" },
    { "code": "MY", "label": "Malaysia", "number": "458" },
    { "code": "MV", "label": "Maldives", "number": "462" },
    { "code": "ML", "label": "Mali", "number": "466" },
    { "code": "MT", "label": "Malta", "number": "470" },
    { "code": "MH", "label": "Marshall Islands (the)", "number": "584" },
    { "code": "MQ", "label": "Martinique", "number": "474" },
    { "code": "MR", "label": "Mauritania", "number": "478" },
    { "code": "MU", "label": "Mauritius", "number": "480" },
    { "code": "YT", "label": "Mayotte", "number": "175" },
    { "code": "MX", "label": "Mexico", "number": "484" },
    { "code": "FM", "label": "Micronesia (Federated States of)", "number": "583" },
    { "code": "MD", "label": "Moldova (the Republic of)", "number": "498" },
    { "code": "MC", "label": "Monaco", "number": "492" },
    { "code": "MN", "label": "Mongolia", "number": "496" },
    { "code": "ME", "label": "Montenegro", "number": "499" },
    { "code": "MS", "label": "Montserrat", "number": "500" },
    { "code": "MA", "label": "Morocco", "number": "504" },
    { "code": "MZ", "label": "Mozambique", "number": "508" },
    { "code": "MM", "label": "Myanmar", "number": "104" },
    { "code": "NA", "label": "Namibia", "number": "516" },
    { "code": "NR", "label": "Nauru", "number": "520" },
    { "code": "NP", "label": "Nepal", "number": "524" },
    { "code": "NL", "label": "Netherlands (the)", "number": "528" },
    { "code": "NC", "label": "New Caledonia", "number": "540" },
    { "code": "NZ", "label": "New Zealand", "number": "554" },
    { "code": "NI", "label": "Nicaragua", "number": "558" },
    { "code": "NE", "label": "Niger (the)", "number": "562" },
    { "code": "NG", "label": "Nigeria", "number": "566" },
    { "code": "NU", "label": "Niue", "number": "570" },
    { "code": "NF", "label": "Norfolk Island", "number": "574" },
    { "code": "MP", "label": "Northern Mariana Islands (the)", "number": "580" },
    { "code": "NO", "label": "Norway", "number": "578" },
    { "code": "OM", "label": "Oman", "number": "512" },
    { "code": "PK", "label": "Pakistan", "number": "586" },
    { "code": "PW", "label": "Palau", "number": "585" },
    { "code": "PS", "label": "Palestine, State of", "number": "275" },
    { "code": "PA", "label": "Panama", "number": "591" },
    { "code": "PG", "label": "Papua New Guinea", "number": "598" },
    { "code": "PY", "label": "Paraguay", "number": "600" },
    { "code": "PE", "label": "Peru", "number": "604" },
    { "code": "PH", "label": "Philippines (the)", "number": "608" },
    { "code": "PN", "label": "Pitcairn", "number": "612" },
    { "code": "PL", "label": "Poland", "number": "616" },
    { "code": "PT", "label": "Portugal", "number": "620" },
    { "code": "PR", "label": "Puerto Rico", "number": "630" },
    { "code": "QA", "label": "Qatar", "number": "634" },
    { "code": "MK", "label": "Republic of North Macedonia", "number": "807" },
    { "code": "RO", "label": "Romania", "number": "642" },
    { "code": "RU", "label": "Russian Federation (the)", "number": "643" },

    { "code": "RE", "label": "Réunion", "number": "638" },
    { "code": "BL", "label": "Saint Barthélemy", "number": "652" },
    { "code": "SH", "label": "Saint Helena, Ascension and Tristan da Cunha", "number": "654" },
    { "code": "KN", "label": "Saint Kitts and Nevis", "number": "659" },
    { "code": "LC", "label": "Saint Lucia", "number": "662" },
    { "code": "MF", "label": "Saint Martin (French part)", "number": "663" },
    { "code": "PM", "label": "Saint Pierre and Miquelon", "number": "666" },
    { "code": "VC", "label": "Saint Vincent and the Grenadines", "number": "670" },
    { "code": "WS", "label": "Samoa", "number": "882" },
    { "code": "SM", "label": "San Marino", "number": "674" },
    { "code": "ST", "label": "Sao Tome and Principe", "number": "678" },
    { "code": "SA", "label": "Saudi Arabia", "number": "682" },
    { "code": "SN", "label": "Senegal", "number": "686" },
    { "code": "RS", "label": "Serbia", "number": "688" },
    { "code": "SC", "label": "Seychelles", "number": "690" },
    { "code": "SL", "label": "Sierra Leone", "number": "694" },
    { "code": "SG", "label": "Singapore", "number": "702" },
    { "code": "SX", "label": "Sint Maarten (Dutch part)", "number": "534" },
    { "code": "SK", "label": "Slovakia", "number": "703" },
    { "code": "SI", "label": "Slovenia", "number": "705" },
    { "code": "SB", "label": "Solomon Islands", "number": "090" },
    { "code": "SO", "label": "Somalia", "number": "706" },
    { "code": "ZA", "label": "South Africa", "number": "710" },
    { "code": "GS", "label": "South Georgia and the South Sandwich Islands", "number": "239" },
    { "code": "SS", "label": "South Sudan", "number": "728" },
    { "code": "ES", "label": "Spain", "number": "724" },
    { "code": "LK", "label": "Sri Lanka", "number": "144" },
    { "code": "SD", "label": "Sudan (the)", "number": "729" },
    { "code": "SJ", "label": "Svalbard and Jan Mayen", "number": "744" },
    { "code": "SE", "label": "Sweden", "number": "752" },
    { "code": "CH", "label": "Switzerland", "number": "756" },
    { "code": "SY", "label": "Syrian Arab Republic", "number": "760" },
    { "code": "TW", "label": "Taiwan", "number": "158" },
    { "code": "TJ", "label": "Tajikistan", "number": "762" },
    { "code": "TZ", "label": "Tanzania, United Republic of", "number": "834" },
    { "code": "TH", "label": "Thailand", "number": "764" },
    { "code": "TL", "label": "Timor-Leste", "number": "626" },
    { "code": "TG", "label": "Togo", "number": "768" },
    { "code": "TK", "label": "Tokelau", "number": "772" },
    { "code": "TO", "label": "Tonga", "number": "776" },
    { "code": "TT", "label": "Trinidad and Tobago", "number": "780" },
    { "code": "TN", "label": "Tunisia", "number": "788" },
    { "code": "TR", "label": "Turkey", "number": "792" },
    { "code": "TM", "label": "Turkmenistan", "number": "795" },
    { "code": "TC", "label": "Turks and Caicos Islands (the)", "number": "796" },
    { "code": "TV", "label": "Tuvalu", "number": "798" },
    { "code": "UG", "label": "Uganda", "number": "800" },
    { "code": "UA", "label": "Ukraine", "number": "804" },
    { "code": "AE", "label": "United Arab Emirates (the)", "number": "784" },
    { "code": "GB", "label": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826" },
    { "code": "UM", "label": "United States Minor Outlying Islands (the)", "number": "581" },
    { "code": "US", "label": "United States of America (the)", "number": "840" },
    { "code": "UY", "label": "Uruguay", "number": "858" },
    { "code": "UZ", "label": "Uzbekistan", "number": "860" },
    { "code": "VU", "label": "Vanuatu", "number": "548" },
    { "code": "VE", "label": "Venezuela (Bolivarian Republic of)", "number": "862" },
    { "code": "VN", "label": "Viet Nam", "number": "704" },
    { "code": "VG", "label": "Virgin Islands (British)", "number": "092" },
    { "code": "VI", "label": "Virgin Islands (U.S.)", "number": "850" },
    { "code": "WF", "label": "Wallis and Futuna", "number": "876" },
    { "code": "EH", "label": "Western Sahara", "number": "732" },
    { "code": "YE", "label": "Yemen", "number": "887" },
    { "code": "ZM", "label": "Zambia", "number": "894" },
    { "code": "ZW", "label": "Zimbabwe", "number": "716" },
    { "code": "AX", "label": "Åland Islands", "number": "248" }

  ];

  // Define states for profile image and permit image
  const [file, setFile] = useState('');
  const [permitFile, setPermitFile] = useState('');
  const [renderfile, setrenderFile] = useState(null);
  const [renderpermitFile, setrednderPermitFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fistname, setfistnameValue] = useState('');
  const [middleName, setmiddleNameValue] = useState('');
  const [lastName, setlastNameValue] = useState('');
  const [gender, setgenderValue] = useState('');
  const [DOB, setDOBValue] = useState('');
  const [nationality, setnationalityValue] = useState('');
  const [marital, setmaritalValue] = useState('');
  const [ID, setIDValue] = useState('');
  const [Permitid, setPermitidValue] = useState('');
  const [isPermit, setPermitidValidations] = useState(false);
  const [DriverType, setDriverTypeValue] = useState('');
  const [Telephone, setTelephoneValue] = useState('');
  const [insurance, setinsuranceValue] = useState('');
  const [riderStatus, setRiderStatusValue] = useState('');
  // errors state
  const [first_name, setfirst_name] = useState('');
  const [last_name, setlast_name] = useState('');
  const [gendererror, setgendererror] = useState('');
  const [phone_number, setphone_number] = useState('');
  const [date_of_birth, setdate_of_birth] = useState('');
  const [Marital_status, setMarital_status] = useState('');
  const [nationalityError, setnationalityError] = useState('');

  //  assigning values
  const riderStatusHandleChange = (event) => {
    setRiderStatusValue(event.target.value);
  };
  const fistnameHandleChange = (event) => {
    setfistnameValue(event.target.value);
  };
  const middleHandleChange = (event) => {
    setmiddleNameValue(event.target.value);
  };
  const lastnameHandleChange = (event) => {
    setlastNameValue(event.target.value);
  };
  const genderHandleChange = (event) => {
    setgenderValue(event.target.value);

  };
  // const genderHandleChange = (event) => {
  //   setGender(event.target.value);
  // };

  const dobHandleChange = (event) => {
    setDOBValue(event.target.value);
  };

  const martialHandleChange = (event) => {
    setmaritalValue(event.target.value);
    setEditRider({ ...editRider, Marital_status: event.target.value });
  };
  const idHandleChange = (event) => {
    setIDValue(event.target.value);
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length === 16) {
      setIDValue(event.target.value);
      setIdValidations(false)
    } else {
      setIdValidations(true)
    }

  };

  const permitidHandleChange = (event) => {
    setPermitidValue(event.target.value);
    const value = event.target.value;
    const permitValue = value.replace(/\D/g, '');
    if (permitValue.length === 16) {
      setPermitidValidations(false)
    } else {
      setPermitidValidations(true)
    }
  };
  const driverHandleChange = (event) => {
    setDriverTypeValue(event.target.value);
  };
  const telephoneHandleChange = (event) => {
    setTelephoneValue(event.target.value);

    const value = event.target.value;
    const phoneValue = value.replace(/\D/g, '');
    if (phoneValue.length === 10) {
      setPhoneValidations(false)
    } else {
      setPhoneValidations(true)
    }
  };
  const insuranceHandleChange = (event) => {
    setinsuranceValue(event.target.value);
    setEditRider({ ...editRider, has_health_insurance: event.target.value === 'Yes' });
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setnationalityValue(option.label)
  };


  // const handleClick = () => {
  //     onChildEvent()
  // }
  // Permit image upload
  function permitHandleChange(e) {
    setrednderPermitFile(URL.createObjectURL(e.target.files[0]))
    setPermitFile(e.target.files[0]);
  }

  // Profile image upload
  function handleChange(e) {
    setrenderFile(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }

  // end

  const [selectedRange, setSelectedRange] = useState([defaultStartDate, defaultEndDate]);
  const handleDeleteErider = async (deleteRider) => {
    const confirmed = window.confirm('Are you sure you want to delete this item?');
    if (confirmed) {
      try {
        await axios.delete(`https://morideapi.groupeya.com/api/v1/riders/${deleteRider.id}/`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${user_info.access}`
          },
        });
        addToast(`deleted`, {
          appearance: 'success', autoDismiss: true, // Enable auto dismissal
          autoDismissTimeout: 5000,
          transitionDuration: 300,
        });
        dispatch(fetchAsyncRiders({ user_info, searchQuery, currentPage }));
      } catch (error) {
        addToast(error.response.statusText, {
          appearance: 'error', autoDismiss: true, // Enable auto dismissal
          autoDismissTimeout: 5000,
          transitionDuration: 300,
        });
      }
    }
  };

  const handleEditRider = async (event) => {
    const formData = new FormData();
    // Define states for profile image and permit image
    formData.append('picture', file);
    formData.append('status', riderStatus);
    formData.append('picture_with_driver_license', permitFile);
    formData.append('first_name', fistname);
    formData.append('middleName', middleName);
    formData.append('last_name', lastName);
    formData.append('gender', gender);
    formData.append('date_of_birth', DOB);
    formData.append('nationality', nationality);
    formData.append('Marital_status', marital);
    formData.append('id_number', ID);
    formData.append('permit_number', Permitid);
    formData.append('phone_number', Telephone);
    formData.append('has_health_insurance', insurance);

    // clearing errors state
    setfirst_name(null);
    setlast_name('');
    setgendererror('');
    setphone_number('');
    setdate_of_birth('');
    setMarital_status('');
    setnationalityError('');

    try {
      await axios.put(`https://morideapi.groupeya.com/api/v1/riders/${editRider.id}/`, formData, {

        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${user_info.access}`
        },
      });
      addToast(`successfuly updated`, {
        appearance: 'success', autoDismiss: true, // Enable auto dismissal
        autoDismissTimeout: 5000,
        transitionDuration: 300,
      });
      dispatch(fetchAsyncRiders({ user_info, searchQuery, currentPage }));
      setSelectedOption(null);
      setfistnameValue('');
      setmiddleNameValue('');
      setlastNameValue('');
      setgenderValue('');
      setDOBValue('');
      setnationalityValue('');
      setmaritalValue('');
      setIDValue('');
      setPermitidValue('');
      setDriverTypeValue('');
      setTelephoneValue('');
      setinsuranceValue('');
      setrednderPermitFile("");
      setrenderFile("");
      setPermitFile("");
      setFile("");
    } catch (error) {

      if (error.response.status === 400) {

        let riderErros = Object.keys(error.response.data);
        ErrorHandler(riderErros);
        addToast('please fill the required field', {
          appearance: 'error', autoDismiss: true, // Enable auto dismissal
          autoDismissTimeout: 5000,
          transitionDuration: 300,
        });

      } else {
        addToast(error.response.statusText, {
          appearance: 'error', autoDismiss: true, // Enable auto dismissal
          autoDismissTimeout: 5000,
          transitionDuration: 300,
        });
      }


    }

  };
  const ErrorHandler = (fields) => {
    fields.map((field) => {
      if (field === "first_name") {
        setfirst_name("This field may not be blank.");
      }
      if (field === "last_name") {
        setlast_name("This field may not be blank.");
      }
      if (field === "date_of_birth") {
        setdate_of_birth("Date has wrong format.YYYY-MM-DD.");
      }
      if (field === "Marital_status") {
        Marital_status("select a valid choice .");
      }
      if (field === "gender") {
        setgendererror("select a valid choice .");
      }
      if (field === "nationality") {
        setnationalityError("This field may not be blank.");
      }
      if (field === "phone_number") {

        setphone_number("This field may not be blank.");
      } else {
        return true;
      }
    });
  }
  const handleDateRangeChange = (dates) => {
    if (dates) {
      const formattedDates = dates.map(dateObj => moment(dateObj.$d).format("YYYY-MM-DD"));
      setSelectedRange(formattedDates);
    }
  };

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const handleChildEvent = () => {
    // Do something with the data received from the child component
    setOpen(!open);
  };
  // Fetch data based on the selected date range
  const user_info = useSelector(getUser);
  const allRiderList = useSelector(getAllRiders);
  const allpaginatedRiders = useSelector(getAllpaginatedRiders)

  const isLoading = useSelector((state) => state.untapped.isLoading);
  const handleSearch = (event) => {
    setsearchQuery(event.target.value)
  };
  const handleViewChildEvent = () => {
    setViewRider(!viewRider);

  };

  const handleViewRider = (rider) => {
    setViewRiderInfo(rider)
    setViewRider(!viewRider);

  };
  const handleEditChildEvent = () => {
    seteditOpen(false);
  };
  const handlEriderEdit = (rider) => {
    seteditOpen(!editOpen);
    setEditRider(rider)
    fillEditForm(rider)
  }
  const riderhandleImageUrlChange = (imageUrl) => {

    fetch(imageUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a File object from the Blob
        const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        setFile(file);
      })
      .catch((error) => {
        console.error('Error fetching or converting image:', error);
      });

  };
  const handleImageUrlChange = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a File object from the Blob
        const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

        setPermitFile(file);
      })
      .catch((error) => {
        console.error('Error fetching or converting image:', error);
      });
  };
  const fillEditForm = (riderInfo) => {
    setEditInfo(riderInfo);
    setfistnameValue(riderInfo.first_name);
    setmiddleNameValue(riderInfo.middle_name ? riderInfo.middle_name : '');
    setlastNameValue(riderInfo.last_name);
    setgenderValue(riderInfo.gender);
    setDOBValue(riderInfo.date_of_birth);
    setnationalityValue(riderInfo.nationality);
    setSelectedOption({ "code": "RW", "label": riderInfo.nationality });
    setmaritalValue(riderInfo.Marital_status);
    setIDValue(riderInfo.id_number);
    setPermitidValue(riderInfo.permit_number);
    setDriverTypeValue(riderInfo.borrower_driver_type);
    setTelephoneValue(riderInfo.phone_number);
    setinsuranceValue(riderInfo.has_health_insurance);
    setrednderPermitFile(riderInfo.picture_with_driver_license ? riderInfo.picture_with_driver_license : '');
    if (riderInfo.picture_with_driver_license) {
      handleImageUrlChange(riderInfo.picture)
    } else {
      setPermitFile('');
    }
    setrenderFile(riderInfo.picture ? riderInfo.picture : '');
    if (riderInfo.picture) {
      riderhandleImageUrlChange(riderInfo.picture)
    } else {
      setFile('');
    }

    setRiderStatusValue(riderInfo.status);
  }
  useEffect(() => {
    dispatch(fetchAsyncRiders({ user_info, searchQuery, currentPage }));
  }, [dispatch, user_info, searchQuery, currentPage, editRider]);

  // useEffect(() => {
  //   fillEditForm(editRider)
  // }, [editRider])

  return (
    <DashboardLayout>
      <div className='flex justify-center items-center' >
        <div className='bg-white my-4 rounded-lg border container ' >
          <div className='border-b  flex justify-between items-center   mx-3    px-3 py-4' >
            <span>Riders</span>
            <Modal setOpenModal={open} onChildEvent={handleChildEvent} />
            <span>
              <button className='create_btn px-3 py-2 rounded-lg' onClick={handleOpenModal}>Create Rider</button>
            </span>
          </div>
          <div className='   px-3 py-3' >
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <div className="flex items-center justify-between pb-4">
                <div>
                  {/* <DatePicker.RangePicker onChange={handleDateRangeChange} defaultValue={[dayjs(defaultStartDate), dayjs(defaultEndDate)]}
                    format="YYYY-MM-DD" /> */}
                </div>
                <label for="table-search" className="sr-only">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="text" id="table-search" value={searchQuery} onChange={handleSearch} className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80  focus:ring-blue-500 focus:border-blue-500  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by name" />
                </div>
              </div>
              {allRiderList.length ? (<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
                <thead className="text-xs text-gray-700 uppercase dark:text-gray-400 border-b">
                  <tr className=''>
                    <th scope="col" className="p-2 ">
                      Names
                    </th>
                    <th scope="col" className="px-2 py-4">
                      Phone number
                    </th>
                    <th scope="col" className="px-2 py-4">
                      current debt
                    </th>
                    <th scope="col" className="px-2 py-4">
                      total debt
                    </th>
                    <th scope="col" className="px-2 py-4">
                      current days late

                    </th>

                    <th scope="col" className="px-2 py-4">
                      total days late
                    </th>
                    <th scope="col" className="px-2 py-4">
                      Status
                    </th>
                    <th scope="col" className="px-2 py-4">
                      actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {allRiderList.length && (allRiderList.map((rider, index) => (
                    <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-black" key={index} >
                      <td className="px-2 py-3">
                        <div className="flex items-center">

                          <span className='pl-1 flex flex-col justify-start items-center capitalize ' >{rider.first_name}{rider.middle_name ? rider.middle_name : ''} </span>
                        </div>
                      </td>
                      <td className="px-2 py-3">
                        {rider.phone_number ? rider.phone_number : 'N/A'}
                      </td>
                      <th scope="row" className="px-2 py-3 font-medium  whitespace-nowrap ca ">
                        {rider.debt ? (<div className='current_debt' >
                          {rider.debt.current_debt === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{rider.debt.current_debt} </div>}
                          {rider.debt.current_debt >0 && rider.debt.current_debt <= 10000  && <div className='middle  px-2 py-1 text-center rounded-sm text-black ' > {rider.debt.current_debt} </div>}
                          {rider.debt.current_debt > 10000 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {rider.debt.current_debt} </div>}
                        </div>) : "N/A"}

                      </th>
                      <td className="px-2 py-3">
                        {rider.debt ? (<div className='total_debt' >
                          {rider.debt.total_debt === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{rider.debt.total_debt} </div>}
                          {rider.debt.total_debt >0 && rider.debt.total_debt <= 10000 && <div className='middle  px-2 py-1 text-center rounded-sm text-black ' > {rider.debt.total_debt} </div>}
                          {rider.debt.total_debt > 10000 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {rider.debt.total_debt} </div>}
                        </div>) : "N/A"}
                      </td>
                      <td className="px-2 py-3">
                        {rider.debt ? (<div className='current_days_late' >
                          {rider.debt.current_days_late === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{rider.debt.current_days_late} </div>}
                          {rider.debt.current_days_late > 1 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {rider.debt.current_days_late} </div>}

                        </div>) : "N/A"}
                      </td>
                      <td className="px-2 py-3">
                        {rider.debt ? (<div className='total_days_late' >
                          {rider.debt.total_days_late === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{rider.debt.total_days_late} </div>}
                          {rider.debt.total_days_late > 1 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {rider.debt.total_days_late} </div>}

                        </div>) : "N/A"}
                      </td>
                      <td className="px-2 py-3">
                        {rider.status === "Active" && <span className='active' >{rider.status}</span>}
                        {rider.status === "Inactive" && <span className='Inactive' >{rider.status}</span>}
                        {rider.status === "Contract_terminated" && <span className='Contract_terminated' >{rider.status}</span>}
                      </td>
                      <td className="px-2 py-3 flex justify-between">
                        <FontAwesomeIcon icon={faEdit} size="1x" className='cursor-pointer ' onClick={() => handlEriderEdit(rider)} />
                        <FontAwesomeIcon icon={faUsersViewfinder} size="1x" className='cursor-pointer text-gray-900 mx-5' onClick={() => handleViewRider(rider)} />
                        <FontAwesomeIcon icon={faTrash} size="1x" className='cursor-pointer text-gray-900  ' onClick={() => handleDeleteErider(rider)} />
                        <PopModal setOpenModal={viewRider} onChildEvent={handleViewChildEvent} Title={modalRiderTitle} button={Moridebtn_name} >
                          <div className="relative mt-6 flex-1 px-4 sm:px-6 " >
                            <div className='flex  justify-between'>
                              <div className="profile_container bg-gray-50 ">
                                {viewRiderInfo.picture ? (<img src={viewRiderInfo.picture} alt="Image Icon" className="image" />) : (<img src={upload} alt="Image Icon" className="image" />)}
                              </div>
                              <div className=' user_info flex-col justify-center  bg-gray-50 rounded-name p-3 ' >
                                <div className='flex justify-between ' >
                                  <label class="block w-1/2 mr-2">
                                    <span class="block text-sm font-medium text-slate-700">First name</span>
                                    {viewRiderInfo && <p class="mt-2 text-pink-600 text-sm">
                                      {viewRiderInfo.first_name}
                                    </p>}
                                  </label>
                                  <label class="block w-1/2 ">
                                    <span class="block text-sm font-medium text-slate-700">Middle name</span>

                                    <p class="mt-2  invisible peer-invalid:visible text-pink-600 text-sm">
                                      {viewRiderInfo.middle_name}
                                    </p>
                                  </label>
                                </div>
                                <div className='flex justify-between' >

                                  <label class="block w-1/2 mr-2 ">
                                    <span class="block text-sm font-medium text-slate-700">Last name</span>

                                    {viewRiderInfo && <p class="mt-2  text-pink-600 text-sm">
                                      {viewRiderInfo.last_name}
                                    </p>}
                                  </label>

                                  <label class="block w-1/2 mr-2 ">
                                    <span class="block text-sm font-medium text-slate-700">Gender</span>

                                    {viewRiderInfo && <p class="mt-2  text-pink-600 text-sm">
                                      {viewRiderInfo.gender}
                                    </p>}
                                  </label>

                                </div>
                              </div>
                            </div>

                            <div className='flex justify-between  form_control bg-gray-50 p-3 ' >
                              <label class="block   w-full ">
                                <span class="block text-sm font-medium text-slate-700">Date of birth</span>

                                {viewRiderInfo && <p class="mt-2 text-pink-600 text-sm">
                                  {viewRiderInfo.date_of_birth}
                                </p>}
                              </label>
                              <label class="block mx-2 w-full ">
                                <span class="block text-sm font-medium text-slate-700">Nationality</span>

                                {viewRiderInfo && <p class="mt-2  text-pink-600 text-sm">
                                  {viewRiderInfo.nationality}
                                </p>}
                              </label>

                            </div>
                            <div className='form_control flex justify-between  bg-gray-50 p-3 ' >
                              <label class="block w-1/2 mr-2 ">
                                <span class="block text-sm font-medium text-slate-700">ID number</span>

                                <p class="  text-pink-600 text-sm">
                                  {viewRiderInfo.id_number}
                                </p>
                              </label>
                              <label class="block w-1/2">
                                <span class="block text-sm font-medium text-slate-700">Permit number</span>

                                <p class="mt-2  text-pink-600 text-sm">
                                  {viewRiderInfo.permit_number}
                                </p>
                              </label>
                            </div>
                            <div className='form_control flex justify-between  bg-gray-50 p-3 ' >
                              <label class="block w-1/2  ">
                                <span class="block text-sm font-medium text-slate-700 pb-2">Current debt</span>


                                {viewRiderInfo.debt ? (<div className='current_debt' >
                                  {viewRiderInfo.debt.current_debt === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{viewRiderInfo.debt.current_debt} </div>}
                                  {viewRiderInfo.debt.current_debt <= 10000 && <div className='middle  px-2 py-1 text-center rounded-sm text-black ' > {viewRiderInfo.debt.current_debt} </div>}
                                  {viewRiderInfo.debt.current_debt > 10000 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {viewRiderInfo.debt.current_debt} </div>}
                                </div>) : "N/A"}

                              </label>
                              <label class="block w-1/2 mx-2">
                                <span class="block text-sm font-medium text-slate-700 pb-2">Total debt</span>
                                {viewRiderInfo.debt ? (<div className='current_debt' >
                                  {viewRiderInfo.debt.total_debt === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{viewRiderInfo.debt.total_debt} </div>}
                                  {viewRiderInfo.debt.total_debt <= 10000 && <div className='middle  px-2 py-1 text-center rounded-sm text-black ' > {viewRiderInfo.debt.total_debt} </div>}
                                  {viewRiderInfo.debt.total_debt > 10000 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {viewRiderInfo.debt.total_debt} </div>}
                                </div>) : "N/A"}

                              </label>
                              <label class="block w-1/2">
                                <span class="block text-sm font-medium text-slate-700 pb-2">Current days late </span>

                                {viewRiderInfo.debt ? (<div className='current_days_late' >
                                  {viewRiderInfo.debt.current_days_late === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{viewRiderInfo.debt.current_days_late} </div>}
                                  {viewRiderInfo.debt.current_days_late > 1 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {viewRiderInfo.debt.current_days_late} </div>}

                                </div>) : "N/A"}

                              </label>
                              <label class="block w-1/2 ml-2">
                                <span class="block text-sm font-medium text-slate-700 pb-2">Total days late </span>
                                {viewRiderInfo.debt ? (<div className='total_days_late' >
                                  {viewRiderInfo.debt.total_days_late === 0 && <div className='below  px-2 py-1 text-center rounded-sm text-black ' >{viewRiderInfo.debt.total_days_late} </div>}
                                  {viewRiderInfo.debt.total_days_late > 1 && <div className='high  px-2 py-1 text-center rounded-sm text-black ' > {viewRiderInfo.debt.total_days_late} </div>}

                                </div>) : "N/A"}


                              </label>
                            </div>

                            <div className='flex  justify-between bg-gray-50 rounded-footer'>
                              <div className=' user_info flex-col p-3 ' >
                                <div className='flex ' >
                                  <label class="block w-1/2 ">
                                    <span class="block text-sm font-medium text-slate-700"> Telephone</span>

                                    {viewRiderInfo && <p class="mt-2 text-pink-600 text-sm">
                                      {viewRiderInfo.phone_number}
                                    </p>}
                                  </label>
                                  <label class="block w-1/2 ">
                                    <span class="block text-sm font-medium text-slate-700">Rider status</span>

                                    {viewRiderInfo && <p class="mt-2  text-pink-600 text-sm">
                                      {viewRiderInfo.status === "Active" && <span className='active' >{viewRiderInfo.status}</span>}
                                      {viewRiderInfo.status === "Inactive" && <span className='Inactive' >{viewRiderInfo.status}</span>}
                                      {viewRiderInfo.status === "Contract_terminated" && <span className='Contract_terminated' >{viewRiderInfo.status}</span>}
                                    </p>}
                                  </label>
                                </div>


                                <div className='flex justify-between   ' >
                                  <label class="block w-full ">
                                    <span class="block text-sm font-medium text-slate-700"> Marital status</span>

                                    {viewRiderInfo && <p class="mt-2 text-pink-600 text-sm">
                                      {viewRiderInfo.Marital_status}
                                    </p>}
                                  </label>
                                  <label class="block w-full ">
                                    <span class="block text-sm font-medium text-slate-700"> Has health insurance</span>
                                    {viewRiderInfo && <p class="mt-2 text-pink-600 text-sm">
                                      {viewRiderInfo.has_health_insurance ? 'YES' : 'NO'}
                                    </p>}
                                  </label>
                                </div>
                              </div>

                              <div className="permit_container ">
                                {viewRiderInfo.picture_with_driver_license ? (<img src={viewRiderInfo.picture_with_driver_license} alt="Image Icon" className="image" />) : (<img src={upload} alt="Image Icon" className="image" />)}
                              </div>
                            </div>
                          </div>
                        </PopModal>
                        <PopModal setOpenModal={editOpen} onChildEvent={handleEditChildEvent} onChildSaveEvent={handleEditRider} Title={modalTitle} button={btn_name} >
                          <div className="relative mt-6 flex-1 px-4 sm:px-6  " id='my-main-container' ref={myElementRef}>

                            <div className='flex  justify-between'>
                              <div className="upload_container  ">
                                <span class="block text-sm font-medium text-slate-700">Profile</span>
                                {renderfile ? (
                                  <>
                                    <img src={renderfile} alt="Selected Image" className="image" />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={handleChange}
                                      className="input"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label htmlFor="uploadInput" className="label">
                                      <img src={upload} alt="Image Icon" className="image" />
                                    </label>
                                    <input
                                      id="uploadInput"
                                      type="file"
                                      accept="image/*"
                                      onChange={handleChange}
                                      className="input-hidden"
                                    />
                                  </>
                                )}
                              </div>
                              <div className=' user_info flex-col justify-center mt-4 ' >
                                <div className='flex justify-between ' >
                                  <label class="block w-1/2 mr-2">
                                    <span class="block text-sm font-medium text-slate-700">First name</span>
                                    <input type="name" class="peer ..." value={fistname} onChange={fistnameHandleChange} placeholder='First name' />
                                    {first_name && <p class="mt-2 text-pink-600 text-sm">
                                      {first_name}
                                    </p>}
                                  </label>
                                  <label class="block w-1/2 ">
                                    <span class="block text-sm font-medium text-slate-700">Middle name</span>
                                    <input type="name" class="peer ..." value={middleName} onChange={middleHandleChange} placeholder='Middle name' />
                                    <p class="mt-2  invisible peer-invalid:visible text-pink-600 text-sm">
                                      Middle name
                                    </p>
                                  </label>
                                </div>
                                <div className='flex justify-between items-center ' >
                                  <label class="block w-1/2 mr-2 ">
                                    <span class="block text-sm font-medium text-slate-700">Last name</span>
                                    <input type="name" class="peer ..." value={lastName} onChange={lastnameHandleChange} placeholder='Last name' />
                                    {last_name && <p class="mt-2  text-pink-600 text-sm">
                                      {last_name}
                                    </p>}
                                  </label>
                                  <fieldset className='flex flex-col w-1/2 border rounded-lg p-1 fieldset'>
                                    <legend className='legend'>Gender status</legend>
                                    <span className='flex justify-between'>
                                      <input
                                        id="draft"
                                        className="peer/draft radio"
                                        type="radio"
                                        checked={gender === 'M'}
                                        value='M'
                                        onChange={(event) => genderHandleChange(event)}
                                        name="status"
                                      />
                                      <label htmlFor="draft" className="peer-checked/draft:text-sky-500">
                                        Male
                                      </label>
                                      <input
                                        id="published"
                                        className="peer/published radio"
                                        type="radio"
                                        checked={gender === 'F'}
                                        value='F'
                                        onChange={(event) => genderHandleChange(event)}
                                        name="status"
                                      />
                                      <label htmlFor="published" className="peer-checked/published:text-sky-500">
                                        Female
                                      </label>
                                    </span>
                                    {gendererror && (
                                      <p className="text-pink-600 text-sm">
                                        {gendererror}
                                      </p>
                                    )}
                                  </fieldset>

                                </div>
                              </div>
                            </div>
                            <div className='flex justify-between mt-3 form_control  ' >
                              <label class="block   w-full ">
                                <span class="block text-sm font-medium text-slate-700">Date of birth</span>
                                <input type="date" class="peer ..." value={DOB} onChange={dobHandleChange} max={maxDate} placeholder='Last name' />
                                {date_of_birth && <p class="mt-2 text-pink-600 text-sm">
                                  {date_of_birth}
                                </p>}
                              </label>
                              <label class="block mx-2 w-full ">
                                <span class="block text-sm font-medium text-slate-700">Nationality</span>
                                <div className='multiselect-container' >
                                  <Select
                                    options={options}
                                    value={selectedOption}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    placeholder="Select an option"
                                    classNamePrefix="my-select"
                                  />
                                </div>
                                {nationalityError && <p class="mt-2  text-pink-600 text-sm">
                                  {nationalityError}
                                </p>}
                              </label>
                            </div>
                            <div className='form_control flex justify-between mt-3 ' >
                              <label class="block w-1/2 mr-2 ">
                                <span class="block text-sm font-medium text-slate-700">ID number</span>
                                <input type="text" class="peer ..." placeholder='ID' value={ID} onChange={idHandleChange} />
                                {!idValidations == false && <p class="  text-pink-600 text-sm">
                                  Please ID must be 16 digits/ {ID.length}
                                </p>}
                              </label>
                              <label class="block w-1/2">
                                <span class="block text-sm font-medium text-slate-700">Permit number</span>
                                <input type="text" class="peer ..." placeholder='Permit' value={Permitid} onChange={permitidHandleChange} />
                                {!isPermit == false && <p class="  text-pink-600 text-sm">
                                  Please ID must be 16 digits/ {Permitid.length}
                                </p>}
                              </label>
                            </div>
                            <div className='flex  justify-between '>
                              <div className=' user_info flex flex-col justify-around items-center  ' >
                                <div className='flex  w-full mt-4'>
                                  <label class="block w-full mr-2 ">
                                    <span class="block text-sm font-medium text-slate-700"> Telephone</span>
                                    <input type="text" class="peer ..." placeholder='Telephone' value={Telephone} onChange={telephoneHandleChange} />
                                    {phone_number && <p class="mt-2 text-pink-600 text-sm">
                                      {phone_number}
                                    </p>}
                                    {!phoneValidations == false && <p class="  text-pink-600 text-sm">
                                      phone number must be 10 digits/ {Telephone.length}
                                    </p>}
                                  </label>
                                  <label class="block w-full ">
                                    <span class="block text-sm font-medium text-slate-700"> Status</span>
                                    <select className='border w-full' value={riderStatus} onChange={riderStatusHandleChange}  >

                                      <option value='Active' >Active</option>
                                      <option value='Inactive' >Inactive</option>
                                      <option value='Contract_terminated' >Contract terminated</option>
                                      <option></option>
                                    </select>
                                    {phone_number && <p class="mt-2 text-pink-600 text-sm">
                                      {phone_number}
                                    </p>}
                                  </label>
                                </div>

                                <div className='flex justify-between  w-full'>
                                  <fieldset className='flex flex-col border rounded-lg p-1  fieldset mr-2' >
                                    <legend className='legend' >Marital status</legend>
                                    <span className='flex justify-between ' >
                                      <input id="draft" class="peer/draft radio" type="radio" checked={editRider.Marital_status === 'S'} name="Marital" value='S' onChange={(event) => martialHandleChange(event)} />
                                      <label for="draft" class="peer-checked/draft:text-sky-500">Single</label>
                                      <input id="published" checked={editRider.Marital_status === "M"} class="peer/published radio" type="radio" name="Marital" value='M' onChange={(event) => martialHandleChange(event)} />
                                      <label for="published" class="peer-checked/published:text-sky-500">Married</label>
                                    </span>
                                    {Marital_status && <p class="text-pink-600 text-sm">
                                      {Marital_status}
                                    </p>}
                                  </fieldset>
                                  <fieldset className='flex flex-col border rounded-lg p-1 fieldset'>
                                    <legend className='legend'>Has health insurance</legend>
                                    <span className='flex justify-between'>
                                      <input
                                        id="yes"
                                        className="peer/yes radio"
                                        type="radio"
                                        checked={editRider.has_health_insurance === true}
                                        name="insurance"
                                        value='Yes'
                                        onChange={insuranceHandleChange}
                                      />
                                      <label htmlFor="yes" className="peer-checked/yes:text-sky-500">Yes</label>
                                      <input
                                        id="no"
                                        className="peer/no radio"
                                        type="radio"
                                        checked={editRider.has_health_insurance === false}
                                        name="insurance"
                                        value='No'
                                        onChange={insuranceHandleChange}
                                      />
                                      <label htmlFor="no" className="peer-checked/no:text-sky-500">No</label>
                                    </span>
                                  </fieldset>
                                </div>
                              </div>
                              <div className="upload_container">
                                <span class="block text-sm font-medium text-slate-700">Picture with Driving license</span>
                                {renderpermitFile ? (
                                  <>
                                    <img src={renderpermitFile} alt="Selected Image" className="image" />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={permitHandleChange}
                                      className="input"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label htmlFor="uploadInputPermit" className="label">
                                      <img src={upload} alt="Image Icon" className="image" />
                                    </label>
                                    <input
                                      id="uploadInputPermit"
                                      type="file"
                                      accept="image/*"
                                      onChange={permitHandleChange}
                                      className="input-hidden"
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </PopModal>
                      </td>
                    </tr>
                  )))
                  }
                </tbody>

              </table>) : (isLoading ? (<div role="status" className='flex justify-center my-5' >
                <svg aria-hidden="true" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>) : (<div className='flex justify-center m-5 p-4'>
                No riders
              </div>))}
            </div>
            <div className='flex justify-end my-3' >
              <Pagination defaultCurrent={1} total={allpaginatedRiders.count} onChange={handlePageChange} className="border p-3 rounded-lg" />
            </div>

          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default FuelEssance