import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import upload from "../../images/upload.svg";
import Select from 'react-select';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from 'react-toast-notifications';
import { fetchAsyncRiders, getUser } from '../../../redux/transactions/UntappedSlice';

export default function Modal({ setOpenModal, activateEditRider, onChildEvent }) {
    const { addToast } = useToasts();
    const dispatch = useDispatch()
    const [isEditInfo, setIsEditInfo] = useState(false);
    const [EditInfo, setEditInfo] = useState('');
    const [open, setOpen] = useState(false);


    const options = [
        { "code": "RW", "label": "Rwanda", "number": "646" },
        { "code": "AF", "label": "Afghanistan", "number": "004" },
        { "code": "AL", "label": "Albania", "number": "008" },
        { "code": "DZ", "label": "Algeria", "number": "012" },
        { "code": "AS", "label": "American Samoa", "number": "016" },
        { "code": "AD", "label": "Andorra", "number": "020" },
        { "code": "AO", "label": "Angola", "number": "024" },
        { "code": "AI", "label": "Anguilla", "number": "660" },
        { "code": "AQ", "label": "Antarctica", "number": "010" },
        { "code": "AG", "label": "Antigua and Barbuda", "number": "028" },
        { "code": "AR", "label": "Argentina", "number": "032" },
        { "code": "AM", "label": "Armenia", "number": "051" },
        { "code": "AW", "label": "Aruba", "number": "533" },
        { "code": "AU", "label": "Australia", "number": "036" },
        { "code": "AT", "label": "Austria", "number": "040" },
        { "code": "AZ", "label": "Azerbaijan", "number": "031" },
        { "code": "BS", "label": "Bahamas (the)", "number": "044" },
        { "code": "BH", "label": "Bahrain", "number": "048" },
        { "code": "BD", "label": "Bangladesh", "number": "050" },
        { "code": "BB", "label": "Barbados", "number": "052" },
        { "code": "BY", "label": "Belarus", "number": "112" },
        { "code": "BE", "label": "Belgium", "number": "056" },
        { "code": "BZ", "label": "Belize", "number": "084" },
        { "code": "BJ", "label": "Benin", "number": "204" },
        { "code": "BM", "label": "Bermuda", "number": "060" },
        { "code": "BT", "label": "Bhutan", "number": "064" },
        { "code": "BO", "label": "Bolivia (Plurinational State of)", "number": "068" },
        { "code": "BQ", "label": "Bonaire, Sint Eustatius and Saba", "number": "535" },
        { "code": "BA", "label": "Bosnia and Herzegovina", "number": "070" },
        { "code": "BW", "label": "Botswana", "number": "072" },
        { "code": "BV", "label": "Bouvet Island", "number": "074" },
        { "code": "BR", "label": "Brazil", "number": "076" },
        { "code": "IO", "label": "British Indian Ocean Territory (the)", "number": "086" },
        { "code": "BN", "label": "Brunei Darussalam", "number": "096" },
        { "code": "BG", "label": "Bulgaria", "number": "100" },
        { "code": "BF", "label": "Burkina Faso", "number": "854" },
        { "code": "BI", "label": "Burundi", "number": "108" },
        { "code": "CV", "label": "Cabo Verde", "number": "132" },
        { "code": "KH", "label": "Cambodia", "number": "116" },
        { "code": "CM", "label": "Cameroon", "number": "120" },
        { "code": "CA", "label": "Canada", "number": "124" },
        { "code": "KY", "label": "Cayman Islands (the)", "number": "136" },
        { "code": "CF", "label": "Central African Republic (the)", "number": "140" },
        { "code": "TD", "label": "Chad", "number": "148" },
        { "code": "CL", "label": "Chile", "number": "152" },
        { "code": "CN", "label": "China", "number": "156" },
        { "code": "CX", "label": "Christmas Island", "number": "162" },
        { "code": "CC", "label": "Cocos (Keeling) Islands (the)", "number": "166" },
        { "code": "CO", "label": "Colombia", "number": "170" },
        { "code": "KM", "label": "Comoros (the)", "number": "174" },
        { "code": "CD", "label": "Congo (the Democratic Republic of the)", "number": "180" },
        { "code": "CG", "label": "Congo (the)", "number": "178" },
        { "code": "CK", "label": "Cook Islands (the)", "number": "184" },
        { "code": "CR", "label": "Costa Rica", "number": "188" },
        { "code": "HR", "label": "Croatia", "number": "191" },
        { "code": "CU", "label": "Cuba", "number": "192" },
        { "code": "CW", "label": "Curaçao", "number": "531" },
        { "code": "CY", "label": "Cyprus", "number": "196" },
        { "code": "CZ", "label": "Czechia", "number": "203" },
        { "code": "CI", "label": "Côte d'Ivoire", "number": "384" },
        { "code": "DK", "label": "Denmark", "number": "208" },
        { "code": "DJ", "label": "Djibouti", "number": "262" },
        { "code": "DM", "label": "Dominica", "number": "212" },
        { "code": "DO", "label": "Dominican Republic (the)", "number": "214" },
        { "code": "EC", "label": "Ecuador", "number": "218" },
        { "code": "EG", "label": "Egypt", "number": "818" },
        { "code": "SV", "label": "El Salvador", "number": "222" },
        { "code": "GQ", "label": "Equatorial Guinea", "number": "226" },
        { "code": "ER", "label": "Eritrea", "number": "232" },
        { "code": "EE", "label": "Estonia", "number": "233" },
        { "code": "SZ", "label": "Eswatini", "number": "748" },
        { "code": "ET", "label": "Ethiopia", "number": "231" },
        { "code": "FK", "label": "Falkland Islands (the) [Malvinas]", "number": "238" },
        { "code": "FO", "label": "Faroe Islands (the)", "number": "234" },
        { "code": "FJ", "label": "Fiji", "number": "242" },
        { "code": "FI", "label": "Finland", "number": "246" },
        { "code": "FR", "label": "France", "number": "250" },
        { "code": "GF", "label": "French Guiana", "number": "254" },
        { "code": "PF", "label": "French Polynesia", "number": "258" },
        { "code": "TF", "label": "French Southern Territories (the)", "number": "260" },
        { "code": "GA", "label": "Gabon", "number": "266" },
        { "code": "GM", "label": "Gambia (the)", "number": "270" },
        { "code": "GE", "label": "Georgia", "number": "268" },
        { "code": "DE", "label": "Germany", "number": "276" },
        { "code": "GH", "label": "Ghana", "number": "288" },
        { "code": "GI", "label": "Gibraltar", "number": "292" },
        { "code": "GR", "label": "Greece", "number": "300" },
        { "code": "GL", "label": "Greenland", "number": "304" },
        { "code": "GD", "label": "Grenada", "number": "308" },
        { "code": "GP", "label": "Guadeloupe", "number": "312" },
        { "code": "GU", "label": "Guam", "number": "316" },
        { "code": "GT", "label": "Guatemala", "number": "320" },
        { "code": "GG", "label": "Guernsey", "number": "831" },
        { "code": "GN", "label": "Guinea", "number": "324" },
        { "code": "GW", "label": "Guinea-Bissau", "number": "624" },
        { "code": "GY", "label": "Guyana", "number": "328" },
        { "code": "HT", "label": "Haiti", "number": "332" },
        { "code": "HM", "label": "Heard Island and McDonald Islands", "number": "334" },
        { "code": "VA", "label": "Holy See (the)", "number": "336" },
        { "code": "HN", "label": "Honduras", "number": "340" },
        { "code": "HK", "label": "Hong Kong", "number": "344" },
        { "code": "HU", "label": "Hungary", "number": "348" },
        { "code": "IS", "label": "Iceland", "number": "352" },
        { "code": "IN", "label": "India", "number": "356" },
        { "code": "ID", "label": "Indonesia", "number": "360" },
        { "code": "IR", "label": "Iran (Islamic Republic of)", "number": "364" },
        { "code": "IQ", "label": "Iraq", "number": "368" },
        { "code": "IE", "label": "Ireland", "number": "372" },
        { "code": "IM", "label": "Isle of Man", "number": "833" },
        { "code": "IL", "label": "Israel", "number": "376" },
        { "code": "IT", "label": "Italy", "number": "380" },
        { "code": "JM", "label": "Jamaica", "number": "388" },
        { "code": "JP", "label": "Japan", "number": "392" },
        { "code": "JE", "label": "Jersey", "number": "832" },
        { "code": "JO", "label": "Jordan", "number": "400" },
        { "code": "KZ", "label": "Kazakhstan", "number": "398" },
        { "code": "KE", "label": "Kenya", "number": "404" },
        { "code": "KI", "label": "Kiribati", "number": "296" },
        { "code": "KP", "label": "Korea (the Democratic People's Republic of)", "number": "408" },
        { "code": "KR", "label": "Korea (the Republic of)", "number": "410" },
        { "code": "KW", "label": "Kuwait", "number": "414" },
        { "code": "KG", "label": "Kyrgyzstan", "number": "417" },
        { "code": "LA", "label": "Lao People's Democratic Republic (the)", "number": "418" },
        { "code": "LV", "label": "Latvia", "number": "428" },
        { "code": "LB", "label": "Lebanon", "number": "422" },
        { "code": "LS", "label": "Lesotho", "number": "426" },
        { "code": "LR", "label": "Liberia", "number": "430" },
        { "code": "LY", "label": "Libya", "number": "434" },
        { "code": "LI", "label": "Liechtenstein", "number": "438" },
        { "code": "LT", "label": "Lithuania", "number": "440" },
        { "code": "LU", "label": "Luxembourg", "number": "442" },
        { "code": "MO", "label": "Macao", "number": "446" },
        { "code": "MG", "label": "Madagascar", "number": "450" },
        { "code": "MW", "label": "Malawi", "number": "454" },
        { "code": "MY", "label": "Malaysia", "number": "458" },
        { "code": "MV", "label": "Maldives", "number": "462" },
        { "code": "ML", "label": "Mali", "number": "466" },
        { "code": "MT", "label": "Malta", "number": "470" },
        { "code": "MH", "label": "Marshall Islands (the)", "number": "584" },
        { "code": "MQ", "label": "Martinique", "number": "474" },
        { "code": "MR", "label": "Mauritania", "number": "478" },
        { "code": "MU", "label": "Mauritius", "number": "480" },
        { "code": "YT", "label": "Mayotte", "number": "175" },
        { "code": "MX", "label": "Mexico", "number": "484" },
        { "code": "FM", "label": "Micronesia (Federated States of)", "number": "583" },
        { "code": "MD", "label": "Moldova (the Republic of)", "number": "498" },
        { "code": "MC", "label": "Monaco", "number": "492" },
        { "code": "MN", "label": "Mongolia", "number": "496" },
        { "code": "ME", "label": "Montenegro", "number": "499" },
        { "code": "MS", "label": "Montserrat", "number": "500" },
        { "code": "MA", "label": "Morocco", "number": "504" },
        { "code": "MZ", "label": "Mozambique", "number": "508" },
        { "code": "MM", "label": "Myanmar", "number": "104" },
        { "code": "NA", "label": "Namibia", "number": "516" },
        { "code": "NR", "label": "Nauru", "number": "520" },
        { "code": "NP", "label": "Nepal", "number": "524" },
        { "code": "NL", "label": "Netherlands (the)", "number": "528" },
        { "code": "NC", "label": "New Caledonia", "number": "540" },
        { "code": "NZ", "label": "New Zealand", "number": "554" },
        { "code": "NI", "label": "Nicaragua", "number": "558" },
        { "code": "NE", "label": "Niger (the)", "number": "562" },
        { "code": "NG", "label": "Nigeria", "number": "566" },
        { "code": "NU", "label": "Niue", "number": "570" },
        { "code": "NF", "label": "Norfolk Island", "number": "574" },
        { "code": "MP", "label": "Northern Mariana Islands (the)", "number": "580" },
        { "code": "NO", "label": "Norway", "number": "578" },
        { "code": "OM", "label": "Oman", "number": "512" },
        { "code": "PK", "label": "Pakistan", "number": "586" },
        { "code": "PW", "label": "Palau", "number": "585" },
        { "code": "PS", "label": "Palestine, State of", "number": "275" },
        { "code": "PA", "label": "Panama", "number": "591" },
        { "code": "PG", "label": "Papua New Guinea", "number": "598" },
        { "code": "PY", "label": "Paraguay", "number": "600" },
        { "code": "PE", "label": "Peru", "number": "604" },
        { "code": "PH", "label": "Philippines (the)", "number": "608" },
        { "code": "PN", "label": "Pitcairn", "number": "612" },
        { "code": "PL", "label": "Poland", "number": "616" },
        { "code": "PT", "label": "Portugal", "number": "620" },
        { "code": "PR", "label": "Puerto Rico", "number": "630" },
        { "code": "QA", "label": "Qatar", "number": "634" },
        { "code": "MK", "label": "Republic of North Macedonia", "number": "807" },
        { "code": "RO", "label": "Romania", "number": "642" },
        { "code": "RU", "label": "Russian Federation (the)", "number": "643" },

        { "code": "RE", "label": "Réunion", "number": "638" },
        { "code": "BL", "label": "Saint Barthélemy", "number": "652" },
        { "code": "SH", "label": "Saint Helena, Ascension and Tristan da Cunha", "number": "654" },
        { "code": "KN", "label": "Saint Kitts and Nevis", "number": "659" },
        { "code": "LC", "label": "Saint Lucia", "number": "662" },
        { "code": "MF", "label": "Saint Martin (French part)", "number": "663" },
        { "code": "PM", "label": "Saint Pierre and Miquelon", "number": "666" },
        { "code": "VC", "label": "Saint Vincent and the Grenadines", "number": "670" },
        { "code": "WS", "label": "Samoa", "number": "882" },
        { "code": "SM", "label": "San Marino", "number": "674" },
        { "code": "ST", "label": "Sao Tome and Principe", "number": "678" },
        { "code": "SA", "label": "Saudi Arabia", "number": "682" },
        { "code": "SN", "label": "Senegal", "number": "686" },
        { "code": "RS", "label": "Serbia", "number": "688" },
        { "code": "SC", "label": "Seychelles", "number": "690" },
        { "code": "SL", "label": "Sierra Leone", "number": "694" },
        { "code": "SG", "label": "Singapore", "number": "702" },
        { "code": "SX", "label": "Sint Maarten (Dutch part)", "number": "534" },
        { "code": "SK", "label": "Slovakia", "number": "703" },
        { "code": "SI", "label": "Slovenia", "number": "705" },
        { "code": "SB", "label": "Solomon Islands", "number": "090" },
        { "code": "SO", "label": "Somalia", "number": "706" },
        { "code": "ZA", "label": "South Africa", "number": "710" },
        { "code": "GS", "label": "South Georgia and the South Sandwich Islands", "number": "239" },
        { "code": "SS", "label": "South Sudan", "number": "728" },
        { "code": "ES", "label": "Spain", "number": "724" },
        { "code": "LK", "label": "Sri Lanka", "number": "144" },
        { "code": "SD", "label": "Sudan (the)", "number": "729" },
        { "code": "SJ", "label": "Svalbard and Jan Mayen", "number": "744" },
        { "code": "SE", "label": "Sweden", "number": "752" },
        { "code": "CH", "label": "Switzerland", "number": "756" },
        { "code": "SY", "label": "Syrian Arab Republic", "number": "760" },
        { "code": "TW", "label": "Taiwan", "number": "158" },
        { "code": "TJ", "label": "Tajikistan", "number": "762" },
        { "code": "TZ", "label": "Tanzania, United Republic of", "number": "834" },
        { "code": "TH", "label": "Thailand", "number": "764" },
        { "code": "TL", "label": "Timor-Leste", "number": "626" },
        { "code": "TG", "label": "Togo", "number": "768" },
        { "code": "TK", "label": "Tokelau", "number": "772" },
        { "code": "TO", "label": "Tonga", "number": "776" },
        { "code": "TT", "label": "Trinidad and Tobago", "number": "780" },
        { "code": "TN", "label": "Tunisia", "number": "788" },
        { "code": "TR", "label": "Turkey", "number": "792" },
        { "code": "TM", "label": "Turkmenistan", "number": "795" },
        { "code": "TC", "label": "Turks and Caicos Islands (the)", "number": "796" },
        { "code": "TV", "label": "Tuvalu", "number": "798" },
        { "code": "UG", "label": "Uganda", "number": "800" },
        { "code": "UA", "label": "Ukraine", "number": "804" },
        { "code": "AE", "label": "United Arab Emirates (the)", "number": "784" },
        { "code": "GB", "label": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826" },
        { "code": "UM", "label": "United States Minor Outlying Islands (the)", "number": "581" },
        { "code": "US", "label": "United States of America (the)", "number": "840" },
        { "code": "UY", "label": "Uruguay", "number": "858" },
        { "code": "UZ", "label": "Uzbekistan", "number": "860" },
        { "code": "VU", "label": "Vanuatu", "number": "548" },
        { "code": "VE", "label": "Venezuela (Bolivarian Republic of)", "number": "862" },
        { "code": "VN", "label": "Viet Nam", "number": "704" },
        { "code": "VG", "label": "Virgin Islands (British)", "number": "092" },
        { "code": "VI", "label": "Virgin Islands (U.S.)", "number": "850" },
        { "code": "WF", "label": "Wallis and Futuna", "number": "876" },
        { "code": "EH", "label": "Western Sahara", "number": "732" },
        { "code": "YE", "label": "Yemen", "number": "887" },
        { "code": "ZM", "label": "Zambia", "number": "894" },
        { "code": "ZW", "label": "Zimbabwe", "number": "716" },
        { "code": "AX", "label": "Åland Islands", "number": "248" }

    ];
    // Define states for profile image and permit image
    const [file, setFile] = useState('');
    const [permitFile, setPermitFile] = useState('');
    const [renderfile, setrenderFile] = useState('');
    const [renderpermitFile, setrednderPermitFile] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [fistname, setfistnameValue] = useState('');
    const [middleName, setmiddleNameValue] = useState('');
    const [lastName, setlastNameValue] = useState('');
    const [gender, setgenderValue] = useState('');
    const [DOB, setDOBValue] = useState('');
    const [nationality, setnationalityValue] = useState('');
    const [marital, setmaritalValue] = useState('');
    const [ID, setIDValue] = useState('');
    const [Permitid, setPermitidValue] = useState('');
    const [DriverType, setDriverTypeValue] = useState('');
    const [Telephone, setTelephoneValue] = useState('');
    const [insurance, setinsuranceValue] = useState('');

    const [idValidations, setIdValidations] = useState('');
    const [phoneValidations, setPhoneValidations] = useState(false);
    const [isPermit, setPermitidValidations] = useState(false);

    // errors state
    const [first_name, setfirst_name] = useState('');
    const [last_name, setlast_name] = useState('');
    const [gendererror, setgendererror] = useState('');
    const [phone_number, setphone_number] = useState('');
    const [date_of_birth, setdate_of_birth] = useState('');
    const [Marital_status, setMarital_status] = useState('');
    const [nationalityError, setnationalityError] = useState('');


    const fistnameHandleChange = (event) => {
        setfistnameValue(event.target.value);
    };
    const middleHandleChange = (event) => {
        setmiddleNameValue(event.target.value);
    };
    const lastnameHandleChange = (event) => {
        setlastNameValue(event.target.value);
    };
    const genderHandleChange = (event) => {
        setgenderValue(event.target.value);

    };
    const dobHandleChange = (event) => {
        setDOBValue(event.target.value);
    };

    const martialHandleChange = (event) => {
        setmaritalValue(event.target.value);
    };
    const idHandleChange = (event) => {
        setIDValue(event.target.value);
        setIdValidations(false)
        const value = event.target.value;
        const numericValue = value.replace(/\D/g, '');

        if (numericValue.length == 16) {

            setIdValidations(true)
        }

    };

    const permitidHandleChange = (event) => {
        setPermitidValue(event.target.value);
        const value = event.target.value;
        const permitValue = value.replace(/\D/g, '');
        if (permitValue.length === 16) {
            setPermitidValidations(false)
        } else {
            setPermitidValidations(true)
        }
    };
    const driverHandleChange = (event) => {
        setDriverTypeValue(event.target.value);
    };
    const telephoneHandleChange = (event) => {
        setTelephoneValue(event.target.value);
        const value = event.target.value;
        const phoneValue = value.replace(/\D/g, '');
        if (phoneValue.length === 10) {
            setPhoneValidations(false)
        } else {
            setPhoneValidations(true)
        }
    };
    const insuranceHandleChange = (event) => {
        setinsuranceValue(event.target.value);
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        setnationalityValue(option.label)
    };


    const handleClick = () => {
        onChildEvent()
    }
    // Permit image upload
    function permitHandleChange(e) {
        setrednderPermitFile(URL.createObjectURL(e.target.files[0]))
        setPermitFile(e.target.files[0]);
    }

    // Profile image upload
    function handleChange(e) {
        setrenderFile(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    const user_info = useSelector(getUser);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        // Define states for profile image and permit image
        formData.append('picture', file);
        formData.append('picture_with_driver_license', permitFile);
        formData.append('first_name', fistname);
        formData.append('middleName', middleName);
        formData.append('last_name', lastName);
        formData.append('gender', gender);
        formData.append('date_of_birth', DOB);
        formData.append('nationality', nationality);
        formData.append('Marital_status', marital);
        formData.append('id_number', ID);
        formData.append('permit_number', Permitid);
        formData.append('phone_number', Telephone);
        formData.append('has_health_insurance', insurance);

        // clearing errors state
        setfirst_name(null);
        setlast_name('');
        setgendererror('');
        setphone_number('');
        setdate_of_birth('');
        setMarital_status('');
        setnationalityError('');
        try {

            await axios.post('https://morideapi.groupeya.com/api/v1/riders/', formData, {

                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user_info.access}`
                },
            });
            addToast(`successfuly created`, { appearance: 'success',autoDismiss: true, // Enable auto dismissal
            autoDismissTimeout: 5000,
            transitionDuration: 300, });
            dispatch(fetchAsyncRiders(user_info));
            setSelectedOption(null);
            setfistnameValue('');
            setmiddleNameValue('');
            setlastNameValue('');
            setgenderValue('');
            setDOBValue('');
            setnationalityValue('');
            setmaritalValue('');
            setIDValue('');
            setPermitidValue('');
            setDriverTypeValue('');
            setTelephoneValue('');
            setinsuranceValue('');
            setrednderPermitFile("");
            setrenderFile("");
            setPermitFile("");
            setFile("");


        } catch (error) {

            if (error.response.status === 400) {

                let riderErros = Object.keys(error.response.data);
                ErrorHandler(riderErros);
                addToast('please fill the required field', { appearance: 'error', autoDismiss: true, // Enable auto dismissal
                autoDismissTimeout: 5000,
                transitionDuration: 300, });

            } else {
                addToast(error.response.statusText, { appearance: 'error' , autoDismiss: true, // Enable auto dismissal
                autoDismissTimeout: 5000,
                transitionDuration: 300,});
            }


        }

    }

    const ErrorHandler = (fields) => {
        fields.map((field) => {
            if (field === "first_name") {
                setfirst_name("This field may not be blank.");
            }
            if (field === "last_name") {
                setlast_name("This field may not be blank.");
            }
            if (field === "date_of_birth") {
                setdate_of_birth("Date has wrong format.YYYY-MM-DD.");
            }
            if (field === "Marital_status") {
                Marital_status("select a valid choice .");
            }
            if (field === "gender") {
                setgendererror("select a valid choice .");
            }
            if (field === "nationality") {
                setnationalityError("This field may not be blank.");
            }
            if (field === "phone_number") {

                setphone_number("This field may not be blank.");
            } else {
                return true;
            }
        });
    }

    const fillEditForm = (riderInfo) => {

        setEditInfo(riderInfo);
        // setIsEditInfo(true);
        setfistnameValue(riderInfo.first_name);
        setmiddleNameValue(riderInfo.middle_name);
        setlastNameValue(riderInfo.last_name);
        setgenderValue(riderInfo.gender);
        setDOBValue(riderInfo.date_of_birth);
        setnationalityValue(riderInfo.nationality);
        setSelectedOption({ "code": "RW", "label": riderInfo.nationality });
        setmaritalValue(riderInfo.Marital_status);
        setIDValue(riderInfo.id_number);
        setPermitidValue(riderInfo.permit_number);
        setDriverTypeValue(riderInfo.borrower_driver_type);
        setTelephoneValue(riderInfo.phone_number);
        setinsuranceValue(riderInfo.has_health_insurance);
        setrednderPermitFile(riderInfo.picture);
        setrenderFile(riderInfo.picture);
        setPermitFile(riderInfo.picture_with_driver_license);
        setFile(riderInfo.picture);
    }

    useEffect(() => {
        setOpen(setOpenModal)
        if (activateEditRider) {
            fillEditForm(activateEditRider)
        }


    }, [setOpenModal, activateEditRider]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleClick}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed  bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-lg  ">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                onClick={handleClick}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6 bg-gray-400 rounded-lg text-white" aria-hidden="true" onClose={handleClick} />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6 border-b-8">
                                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 p-4  flex justify-between ">
                                                <span> Create new rider</span>
                                                <span>
                                                    <button className='create_btn px-3 py-2 rounded-lg' onClick={handleSubmit} >Create rider</button>
                                                    {/* <button className='create_btn px-3 py-2 rounded-lg'>Edit rider</button> */}
                                                </span>
                                            </Dialog.Title>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6 ">

                                            <div className='flex  justify-between'>
                                                <div className="upload_container  ">
                                                    <span class="block text-sm font-medium text-slate-700">Profile</span>
                                                    {renderfile ? (
                                                        <>
                                                            <img src={renderfile} alt="Selected Image" className="image" />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleChange}
                                                                className="input"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <label htmlFor="uploadInput" className="label">
                                                                <img src={upload} alt="Image Icon" className="image" />
                                                            </label>
                                                            <input
                                                                id="uploadInput"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleChange}
                                                                className="input-hidden"
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                
                                                <div className=' user_info flex-col justify-center mt-4 ' >
                                                    <div className='flex justify-between ' >
                                                        <label class="block w-1/2 mr-2">
                                                            <span class="block text-sm font-medium text-slate-700">First name</span>
                                                            <input type="name" class="peer ..." value={fistname} onChange={fistnameHandleChange} placeholder='First name' />
                                                            {first_name && <p class="mt-2 text-pink-600 text-sm">
                                                                {first_name}
                                                            </p>}
                                                        </label>
                                                        <label class="block w-1/2 ">
                                                            <span class="block text-sm font-medium text-slate-700">Middle name</span>
                                                            <input type="name" class="peer ..." value={middleName} onChange={middleHandleChange} placeholder='Middle name' />
                                                            <p class="mt-2  invisible peer-invalid:visible text-pink-600 text-sm">
                                                                Middle name
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className='flex justify-between items-center ' >
                                                        <label class="block w-1/2 mr-2 ">
                                                            <span class="block text-sm font-medium text-slate-700">Last name</span>
                                                            <input type="name" class="peer ..." value={lastName} onChange={lastnameHandleChange} placeholder='Last name' />
                                                            {last_name && <p class="mt-2  text-pink-600 text-sm">
                                                                {last_name}
                                                            </p>}
                                                        </label>
                                                        <fieldset className='flex flex-col w-1/2 border rounded-lg p-1 fieldset' >
                                                            <legend className='legend' >Gender status  </legend>
                                                            <span className='flex justify-between ' >
                                                                <input id="draft" class="peer/draft radio" type="radio" value='M' onChange={(event) => genderHandleChange(event)} name="status" />
                                                                <label for="draft" class="peer-checked/draft:text-sky-500">Male</label>
                                                                <input id="published" class="peer/published radio" type="radio" value='F' onChange={(event) => genderHandleChange(event)} name="status" />
                                                                <label for="published" class="peer-checked/published:text-sky-500">Female</label>
                                                            </span>
                                                            {gendererror && <p class="text-pink-600 text-sm">
                                                                {gendererror}
                                                            </p>}
                                                        </fieldset>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='flex justify-between mt-3 form_control  ' >
                                                <label class="block   w-full ">
                                                    <span class="block text-sm font-medium text-slate-700">Date of birth</span>
                                                    <input type="date" class="peer ..." value={DOB} onChange={dobHandleChange} placeholder='Last name' />
                                                    {date_of_birth && <p class="mt-2 text-pink-600 text-sm">
                                                        {date_of_birth}
                                                    </p>}
                                                </label>
                                                <label class="block mx-2 w-full ">
                                                    <span class="block text-sm font-medium text-slate-700">Nationality</span>
                                                    <div className='multiselect-container' >
                                                        <Select
                                                            options={options}
                                                            value={selectedOption}
                                                            onChange={handleSelect}
                                                            isSearchable={true}
                                                            placeholder="Select an option"
                                                            classNamePrefix="my-select"
                                                        />
                                                    </div>
                                                    {nationalityError && <p class="mt-2  text-pink-600 text-sm">
                                                        {nationalityError}
                                                    </p>}
                                                </label>
                                            </div>
                                            <div className='form_control flex justify-between mt-3 ' >
                                                <label class="block w-1/2 mr-2 ">
                                                    <span class="block text-sm font-medium text-slate-700">ID number</span>
                                                    <input type="text" class="peer ..." placeholder='ID' value={ID} onChange={idHandleChange} />
                                                    {ID.length > 1 && ID.length < 16 && <p class="  text-pink-600 text-sm">
                                                        Please ID must be 16. {ID.length}/16
                                                    </p>}
                                                </label>
                                                <label class="block w-1/2">
                                                    <span class="block text-sm font-medium text-slate-700">Permit number</span>
                                                    <input type="text" class="peer ..." placeholder='Permit' value={Permitid} onChange={permitidHandleChange} />
                                                    {Permitid.length > 1 && Permitid.length < 16 && <p class="  text-pink-600 text-sm">
                                                        Please Permitcid must be 16. {Permitid.length}/16
                                                    </p>}
                                                </label>
                                                {/* <label class="block">
                                                    <span class="block text-sm font-medium text-slate-700">Borrower/ Driver Type</span>
                                                    <input type="text" class="peer ..." placeholder='Driver Type' value={DriverType} onChange={driverHandleChange} />
                                                    <p class="mt-2 invisible peer-invalid:visible text-pink-600 text-sm">
                                                        Please provide a valid last name
                                                    </p>
                                                </label> */}
                                            </div>

                                            <div className='flex  justify-between'>
                                                <div className=' user_info flex  flex-col   justify-around   ' >
                                                    <label class="block w-full ">
                                                        <span class="block text-sm font-medium text-slate-700"> Telephone</span>
                                                        <input type="text" class="peer ..." placeholder='Telephone' value={Telephone} onChange={telephoneHandleChange} />
                                                        {phone_number && <p class="mt-2 text-pink-600 text-sm">
                                                            {phone_number}
                                                        </p>}
                                                        {!phoneValidations == false && <p class="  text-pink-600 text-sm">
                                                            phone number must be 10 digits/ {Telephone.length}
                                                        </p>}
                                                        {!phoneValidations == false &&  <p class="  text-pink-600 text-sm">
                                                            this phone number already exist !
                                                        </p>}
                                                    </label>

                                                    <div className='flex    ' >

                                                        <fieldset className='flex flex-col border rounded-lg p-1  fieldset mr-2' >
                                                            <legend className='legend' >Marital status</legend>
                                                            <span className='flex justify-between ' >
                                                                <input id="draft" class="peer/draft radio" type="radio" name="Marital" value='S' onChange={martialHandleChange} />
                                                                <label for="draft" class="peer-checked/draft:text-sky-500">Single</label>
                                                                <input id="published" class="peer/published radio" type="radio" name="Marital" value='M' onChange={martialHandleChange} />
                                                                <label for="published" class="peer-checked/published:text-sky-500">Married</label>
                                                            </span>
                                                            {Marital_status && <p class="text-pink-600 text-sm">
                                                                {Marital_status}
                                                            </p>}
                                                        </fieldset>
                                                        <fieldset className='flex flex-col border rounded-lg p-1 fieldset' >
                                                            <legend className='legend' >Has health insurance</legend>

                                                            <span className='flex justify-between ' >
                                                                <input id="draft" class="peer/draft radio" type="radio" name="insurance" value='Yes' onChange={insuranceHandleChange} />
                                                                <label for="draft" class="peer-checked/draft:text-sky-500">Yes</label>
                                                                <input id="published" class="peer/published radio" type="radio" name="insurance" value='NO' onChange={insuranceHandleChange} />
                                                                <label for="published" class="peer-checked/published:text-sky-500">No</label>
                                                            </span>

                                                        </fieldset>
                                                    </div>
                                                </div>

                                                <div className="upload_container">
                                                    <span class="block text-sm font-medium text-slate-700">Picture with Driving license</span>
                                                    {renderpermitFile ? (
                                                        <>
                                                            <img src={renderpermitFile} alt="Selected Image" className="image" />
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={permitHandleChange}
                                                                className="input"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <label htmlFor="uploadInputPermit" className="label">
                                                                <img src={upload} alt="Image Icon" className="image" />
                                                            </label>
                                                            <input
                                                                id="uploadInputPermit"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={permitHandleChange}
                                                                className="input-hidden"
                                                            />
                                                        </>
                                                    )}
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}