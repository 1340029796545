
import React, { useEffect, useState } from 'react'
import DashboardLayout from "../DashboardLayout"
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import PopModal from "./PopModal";
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { fetchAsyncAsset, fetchAsyncAssigned, fetchAsyncRiders, getAllAsset, getAllAssignedAsset, getAllpaginatedAssignedAsset, getAllRiders, getUser } from '../../../redux/transactions/UntappedSlice';

function AssignAsset() {
    const { addToast } = useToasts();
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD'); // Example: Set default date to the start of the current month
    const defaultEndDate = moment().format('YYYY-MM-DD'); // Set default end date to current date
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedPlate, setSelectedplate] = useState('');
    const [assignedAt, setAssignedAtValue] = useState('');
    const [selectedRange, setSelectedRange] = useState([defaultStartDate, defaultEndDate]);
    const [searchQuery,setsearchQuery]=useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const handleDateRangeChange = (dates) => {
        if (dates) {
            const formattedDates = dates.map(dateObj => moment(dateObj.$d).format("YYYY-MM-DD"));
            setSelectedRange(formattedDates);
        }
    };

    const handleOpenModal = () => {
        setOpen(!open);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        
      };


    const modalTitle = "Assign Asset";
    const btn_name = 'Assign';
    let displayRider = null;
    let displayAsset = null;

    
    const handleSelect = (option) => {
        setSelectedOption(option);
    };
    const handleAssign = (event) => {
        setAssignedAtValue(event.target.value);
    };
    const handleSelectPlate = (option) => {
        setSelectedplate(option);
    };
    const handleChildEvent = () => {
        // Do something with the data received from the child component
        setOpen(!open);
    };
    // Fetch asset
    const allAssetList = useSelector(getAllAsset);
    
    const allRiderList = useSelector(getAllRiders);

    if (allAssetList.length) {
        displayAsset = allAssetList.map((asset) => {
            return {
                value: asset.id,
                label: asset.plate_number
            };
        });
    }
    if (allRiderList.length) {
        displayRider = allRiderList.map((rider) => {
            return {
                value: rider.id,
                label: rider.first_name
            };
        });
    }
    const user_info = useSelector(getUser);

    // state errors
    const [assetError, setassetError] = useState('');
    const [riderError, setriderError] = useState('');
    const [assigned_at, setassigned_at] = useState('');
    const allAssignedAssetList = useSelector(getAllAssignedAsset);
    const allpaginatedAsset=useSelector(getAllpaginatedAssignedAsset);
    const isLoading = useSelector((state) => state.untapped.isLoading);

    const handleAssignAsset = async () => {
        const assetRider = {
            'asset': selectedPlate.value,
            'rider': selectedOption.value,
            'assigned_at': assignedAt
        };
        setassetError('');
        setriderError('');
        setassigned_at('');

        try {

             await axios.post('https://morideapi.groupeya.com/api/v1/assets/assign',

                assetRider,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${user_info.access}`
                    },
                });
            addToast(`successful assigned`, { appearance: 'success' });
            dispatch(fetchAsyncAssigned(user_info))
            setSelectedOption('');
            setSelectedplate('');
            setAssignedAtValue('');

        } catch (error) {
            if (error.response.status == 400) {
                
                let riderErros = Object.keys(error.response.data.message);
                ErrorHandler(riderErros);
                addToast('please fill the required field', { appearance: 'error' });

            } else {
                addToast(error.response.statusText, { appearance: 'error' });
            }
        }



    };
    const ErrorHandler = (fields) => {
        fields.map((field) => {
            if (field === "asset") {
                setassetError("This field may not be blank");
            }
            if (field === "rider") {
                setriderError("This field may not be blank.");
            }
            if (field === "assigned_at") {
                setassigned_at("Date has wrong format, YYYY-MM-DD.");
            } else {
                return true;
            }
        });
    }
    const handleSearch = (event) => {
        setsearchQuery(event.target.value)
    };
    
    useEffect(() => {
        dispatch(fetchAsyncAsset({user_info,searchQuery,currentPage}))
        dispatch(fetchAsyncRiders({user_info,searchQuery,currentPage}))
        dispatch(fetchAsyncAssigned({user_info,searchQuery,currentPage}))
    }, [dispatch ,user_info,searchQuery,currentPage]);

    return (
        <DashboardLayout>
            <div className='flex justify-center items-center' >
                <div className='bg-white my-4 rounded-lg border container ' >
                    <div className='border-b  flex justify-between items-center   mx-3    px-3 py-4' >
                        <span>Riders</span>
                        <PopModal setOpenModal={open} onChildEvent={handleChildEvent} onChildSaveEvent={handleAssignAsset} Title={modalTitle} button={btn_name} >
                            <div>
                                <div className='flex justify-between mt-3 form_control ' >
                                    <label class="block  ">
                                        <span class="block text-sm font-medium text-slate-700">Asset plate number</span>
                                        <div className='multiselect-container' >
                                            {displayAsset && <Select
                                                options={displayAsset}
                                                value={selectedPlate}
                                                onChange={handleSelectPlate}
                                                isSearchable={true}
                                                placeholder="Select an option"
                                                classNamePrefix="my-select"
                                            />}
                                        </div>
                                        {assetError && <p class="mt-2  text-pink-600 text-sm">
                                            {assetError}
                                        </p>}
                                    </label>
                                    <label class="block  ">
                                        <span class="block text-sm font-medium text-slate-700">Rider name</span>
                                        <div className='multiselect-container' >
                                            {displayRider && <Select
                                                options={displayRider}
                                                value={selectedOption}
                                                onChange={handleSelect}
                                                isSearchable={true}
                                                placeholder="Select an option"
                                                classNamePrefix="my-select"
                                            />}
                                        </div>
                                        {riderError && <p class="mt-2  text-pink-600 text-sm">
                                            {riderError}
                                        </p>}
                                    </label>
                                    <label class="block ">
                                        <span class="block text-sm font-medium text-slate-700">Assign Date</span>
                                        <input type="date" class="peer ..." value={assignedAt}
                                            onChange={handleAssign} />
                                        {assigned_at && <p class="mt-2 text-pink-600 text-sm">
                                            {assigned_at}
                                        </p>}
                                    </label>
                                </div>
                            </div>
                        </PopModal>
                        <span>
                            <button className='create_btn px-3 py-2 rounded-lg ' onClick={handleOpenModal}>Assign asset</button>
                        </span>
                    </div>
                    <div className='   px-3 py-3' >
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <div className="flex items-center justify-between pb-4">
                                <div>
                                    {/* <DatePicker.RangePicker onChange={handleDateRangeChange} defaultValue={[dayjs(defaultStartDate), dayjs(defaultEndDate)]}
                                        format="YYYY-MM-DD" /> */}
                                </div>
                                <label for="table-search" className="sr-only">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    </div>
                                    <input type="text" id="table-search" value={searchQuery} onChange={handleSearch}  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80  focus:ring-blue-500 focus:border-blue-500  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                                </div>
                            </div>
                            {allAssignedAssetList.length ? (
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
                                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400 border-b">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                platenumber
                                            </th>

                                            <th scope="col" className="px-6 py-3">
                                                serial number
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Make/model
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                rider
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                assigned at
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                rider/phone number
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allAssignedAssetList.length && (allAssignedAssetList.map((assignedAsset, index) => (
                                            <tr className="bg-white border-b dark:hover:bg-gray-300 dark:hover:text-black" key={index} >
                                                <td className="w-4 p-4">
                                                    <div className="flex items-center">
                                                        <span className='pl-1 flex justify-between items-center capitalize'>{assignedAsset.asset ? assignedAsset.asset.plate_number : 'N/A'} </span>
                                                    </div>
                                                </td>
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                    {assignedAsset.asset.serial_number ? assignedAsset.asset.serial_number : 'N/A'}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {assignedAsset.asset.make ? assignedAsset.asset.make : 'N/A'}/{assignedAsset.asset.model ? assignedAsset.asset.model : 'N/A'}
                                                </td>
                                                <td className="px-6 py-4 capitalize">
                                                    {assignedAsset.rider ? (<span>{assignedAsset.rider.first_name} {assignedAsset.rider.middle_name} {assignedAsset.rider.last_name}</span>) : ('N/A')}

                                                </td>
                                                <td className="px-6 py-4">
                                                    {assignedAsset.assigned_at ? assignedAsset.assigned_at : 'N/A'}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <span className="font-medium text-blue-600 dark:text-blue-500 hover:underline">{assignedAsset.rider.phone_number ? assignedAsset.rider.phone_number : 'N/A'}</span>
                                                </td>
                                            </tr>)))
                                        }

                                    </tbody>
                                </table>) : (isLoading ? (<div role="status" className='flex justify-center my-5' >
                                    <svg aria-hidden="true" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>) : (<div className='flex justify-center m-5 p-4'>
                                    No assigned asset
                                </div>))}

                        </div>
                        <div className='flex justify-end my-3' >
                            <Pagination defaultCurrent={1} total={allpaginatedAsset.count} onChange={handlePageChange}  className="border p-3 rounded-lg" />
                        </div>

                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default AssignAsset
