
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import "./Navbar.css";
import { fetchAsyncLoggedinUser, getloggedinUser, getUser } from "../../redux/transactions/UntappedSlice";
import DashboardApi from '../../api/DashboardApi';

const Navbar = ({ sidebarOpen, openSidebar, logInuser }) => {
  const dispatch = useDispatch()
  const user_info = useSelector(getUser);
  const user_accounts = useSelector(getloggedinUser);
  const [loggedInuser, setloggedInuser] = useState('');


  // const fetchLoggedInUser = async () => {
  //   const response = await DashboardApi.get('/accounts/edit/', {
  //     headers: {
  //       'Accept': 'application/json',
  //       'Authorization': `Bearer ${user_info.access}`
  //     },
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  //   setloggedInuser(response.data)
  // }

  // useEffect(() => { 
  //   fetchLoggedInUser();
  // }, [])


  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.replace('/');

  };
  useEffect(() => {

    if (user_info) {
      dispatch(fetchAsyncLoggedinUser(user_info));
    }

  }, [dispatch, user_info])


  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
      <div className="navbar__left p-5">
        <div class="p-10">



        </div>
      </div>
      <div className="navbar__right my-3 ">
        {(<span className="px-3">Welcome ! <span className="text-fuchsia-500  ">{logInuser.first_name}</span> <div class="dropdown inline-block relative">
          <button class="bg-white text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
            {/* <span class="mr-1">Dropdown</span> */}
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
          </button>
          <ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
            <li class=""><a class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" onClick={() => handleLogout()}>logout</a></li>
          </ul>
        </div></span>)}
        <div class="relative">
          <span className="avatar  flex justify-center items-center p-2 bg-slate-400 text-slate-400">
            <svg class="absolute w-12 h-8 text-white -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
          </span>
          <span class="top-0 left-7 absolute  w-3.5 h-3.5 bg-fuchsia-500 border-2 border-white  rounded-full"></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;