

import React from 'react'
import DashboardLayout from "../DashboardLayout"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dashboardApi from '../../../api/DashboardApi';
import { addTransactions, getUser } from '../../../redux/transactions/UntappedSlice';
import Chart from "./Chart";
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';

function UserProfile() {
    const { addToast } = useToasts();
    //    const dispatch = useDispatch();
    const user_info = useSelector(getUser);

    const [updatePfile, setupdatePfile] = useState(false);
    const [updatepassword, setupdatepassword] = useState(false);

    const [loggedInuser, setloggedInuser] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setmiddleName] = useState('');
    const [lastName, setlastName] = useState('');

    const [userEmail, setuserEmail] = useState('');
    const [userRole, setUserRole] = useState('');
    const [userName, setuserName] = useState('');

    const [oldPassword, setoldPassword] = useState('');
    const [newpassword, setnewpassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');

    const setUpdateProfile = () => {
        setupdatePfile(true);
        setupdatepassword(false);
    };

    const setResetPassword = () => {
        setupdatepassword(true);
        setupdatePfile(false);
    };
    const HandleFirstname = (event) => {
        setFirstName(event.target.value);
    };

    const HandleuserEmail = (event) => {
        setFirstName(event.target.value);
    };
    const HandleuserRole = (event) => {
        setFirstName(event.target.value);
    };
    const HandleuserName = (event) => {
        setFirstName(event.target.value);
    };

    const HandleMiddlename = (event) => {
        setmiddleName(event.target.value);
    };
    const HandleLastname = (event) => {
        setlastName(event.target.value);
    };
    const HandleOldpassword = (event) => {
        setoldPassword(event.target.value);
    };
    const HandleNewpassword = (event) => {
        setnewpassword(event.target.value);
    };
    const HandleConfirmPassword = (event) => {
        setconfirmPassword(event.target.value);
    };

    const fillUpdateForm = () => {
        setFirstName(loggedInuser.first_name);
        setmiddleName('');
        setlastName(loggedInuser.last_name);
        setuserEmail(loggedInuser.email);
        setUserRole(loggedInuser.username);
        setuserName(loggedInuser.username);

    }


    const handlepassword = async () => {
        if (confirmPassword === newpassword) {
            const password = {
                'current_password':oldPassword,
                'new_password':newpassword,

            };

            try {

                await axios.post('https://morideapi.groupeya.com/api/v1/accounts/reset-password/',

                    password,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user_info.access}`
                        },
                    });
                addToast(`successful updated`, {
                    appearance: 'success', autoDismiss: true,
                    autoDismissTimeout: 5000,
                    transitionDuration: 300,
                });
                fetchLoggedInUser()
                setupdatePfile(false);
                setupdatepassword(false);


            } catch (error) {
                addToast(error.response.data.message, {
                    appearance: 'error', autoDismiss: true,
                    autoDismissTimeout: 5000,
                    transitionDuration: 300,
                });



            }
        } else {
            addToast('please ! password are not matching', {
                appearance: 'error', autoDismiss: true,
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });
        }
    };

    const handleupdateprofile = async () => {

        const profile = {
            'first_name': firstName,
            'last_name': lastName,
            'email': userEmail,
            'username': userName,
            'id': loggedInuser.id,
        };

        try {

            await axios.patch('https://morideapi.groupeya.com/api/v1/accounts/edit/',

                profile,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user_info.access}`
                    },
                });
            addToast(`successful updated`, {
                appearance: 'success', autoDismiss: true,
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });
            fetchLoggedInUser()
            setupdatePfile(false);
            setupdatepassword(false);


        } catch (error) {
            addToast(error.response.data.message, {
                appearance: 'error', autoDismiss: true,
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });

        }
    };

    const fetchLoggedInUser = async () => {
        const response = await dashboardApi.get('/accounts/edit/', {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${user_info.access}`
            },
        }).catch((error) => {
            console.log(error)
        })

        setloggedInuser(response.data)
    }
    
    useEffect(() => {
        if (loggedInuser) {
            fillUpdateForm()
        }

    }, [loggedInuser])
    useEffect(() => {
        fetchLoggedInUser();
    }, [])


    return (
        <DashboardLayout>
            <div className=' flex justify-center items-center ' >


                <div className='flex flex-col bg-white user-container rounded-lg' >

                    <div className='flex justify-center items-center p-5' >
                        <div class="relative">
                            <span className="avatar  flex justify-center items-center p-2 bg-slate-400 text-slate-400">
                                <svg class="absolute w-12 h-8 text-white -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                            </span>
                            <span class="top-0 left-7 absolute  w-3.5 h-3.5 bg-fuchsia-500 border-2 border-white  rounded-full"></span>
                        </div>
                    </div>


                    {loggedInuser && <div>
                        <div className='flex justify-between items-center border-t-4  w-full px-2 py-4' >
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">First name</span>
                                <p className="mt-2  text-pink-600 text-sm"> {loggedInuser.first_name ? loggedInuser.first_name : 'N/A'}</p>
                            </div>
                            <div className='update-input mx-2' >
                                <span class="block text-sm font-medium text-slate-700 py-2">Middle name</span>
                                <p className="mt-2  text-pink-600 text-sm"> N/A </p>
                            </div>
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">last name</span>
                                <p className="mt-2  text-pink-600 text-sm"> {loggedInuser.last_name ? loggedInuser.last_name : 'N/A'} </p>
                            </div>
                        </div>
                        <div className='flex justify-between items-center  w-full px-2 py-4' >
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">Email</span>
                                <p className="mt-2  text-pink-600 text-sm"> {loggedInuser.email} </p>
                            </div>
                            <div className='update-input mx-2' >
                                <span class="block text-sm font-medium text-slate-700 py-2">User name</span>
                                <p className="mt-2  text-pink-600 text-sm"> {loggedInuser.username} </p>
                            </div>
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">user Role</span>
                                <p className="mt-2  text-pink-600 text-sm"> {loggedInuser.username} </p>
                            </div>
                        </div>

                        <div className='flex justify-around items-center border-t-4  p-5' >
                            <button className='update-btn px-5 py-1 rounded-md cursor-pointer' onClick={() => setUpdateProfile()} >update profile</button>
                            <button className='update-btn px-5 py-1 rounded-md cursor-pointer' onClick={() => setResetPassword()} >reset password</button>
                        </div>
                    </div>}


                    {updatePfile && <div>
                        <div className='flex justify-between items-center border-t-4  w-full px-2 py-4' >
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">First name</span>
                                <input type="name" className='update-input' value={firstName} onChange={HandleFirstname} placeholder='first name' />
                            </div>
                            <div className='update-input mx-2' >
                                <span class="block text-sm font-medium text-slate-700 py-2">Middle name</span>
                                <input type="name" className='update-input' value={middleName} onChange={HandleMiddlename} placeholder='Middle name' />
                            </div>
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">last name</span>
                                <input type="name" className='update-input' value={lastName} onChange={HandleLastname} placeholder='last name' />
                            </div>
                        </div>
                        <div className='flex justify-between items-center  w-full px-2 py-4' >
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">Email</span>
                                <input type="name" className='update-input' value={userEmail} onChange={HandleuserEmail} placeholder='Email' />
                            </div>
                            <div className='update-input mx-2' >
                                <span class="block text-sm font-medium text-slate-700 py-2">User name</span>
                                <input type="name" className='update-input' value={userRole} onChange={HandleuserRole} placeholder='user name' />
                            </div>
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">user Role</span>
                                <input type="name" className='update-input' disabled value={userName} onChange={HandleuserName} placeholder='user role' />
                            </div>
                        </div>

                        <div className='flex justify-center items-center p-5' >
                            <button className='update-btn px-5 py-1 rounded-md' onClick={() => handleupdateprofile()} >update</button>
                        </div>
                    </div>
                    }

                    {updatepassword && <div>
                        <div className='flex justify-between items-center pt-4 border-t-4 w-full p-2' >
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">Old password</span>
                                <input type="name" className='update-input' value={oldPassword} onChange={HandleOldpassword} placeholder='Old password' />
                            </div>
                            <div className='update-input mx-2' >
                                <span class="block text-sm font-medium text-slate-700 py-2">New Password</span>
                                <input type="name" className='update-input' value={newpassword} onChange={HandleNewpassword} placeholder='New Password' />
                            </div>
                            <div className='update-input' >
                                <span class="block text-sm font-medium text-slate-700 py-2">Confirm Password</span>
                                <input type="name" className='update-input' value={confirmPassword} onChange={HandleConfirmPassword} placeholder='Confirm Password' />
                            </div>
                        </div>
                        <div className='flex justify-center items-center p-5' >
                            <button className='update-btn px-5 py-1 rounded-md' onClick={() => handlepassword()} >update</button>
                        </div>
                    </div>}

                </div>



            </div>
        </DashboardLayout>

    )
}

export default UserProfile