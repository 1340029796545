


import React, { useEffect, useState } from 'react'
import DashboardLayout from "../DashboardLayout"
import moment, { weekdays } from 'moment';
import dayjs from 'dayjs';
import { DatePicker, Space } from 'antd';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import PopModal from "./PopModal";
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { fetchAsyncPayment, getAllAsset, getAllridersPayments, getUser, getAllridersPaymentsPaginatedList, fetchAsyncReceipts, getAllRidersReceipts, getAllPaginatedRidersReceipts } from '../../../redux/transactions/UntappedSlice';

function PaymentsReceipts() {
    const { addToast } = useToasts();
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const handleChildEvent = () => {
        setOpen(!open);
    };
    const handleSubmitAsset = async (event) => {

    }

    const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD'); // Example: Set default date to the start of the current month
    const defaultEndDate = moment().format('YYYY-MM-DD'); // Set default end date to current date
    const [selectedOption, setSelectedOption] = useState('');
    const [riderReceipt, setSelectedRiderReceipt] = useState('');
    const [isThisMonth, setIsThisMonth] = useState(true);
    const [selectedRange, setSelectedRange] = useState([defaultStartDate, defaultEndDate]);
    const [searchQuery, setsearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const handleDateRangeChange = (dates) => {
        if (dates) {
            SetweekFirstMonth('');
            SetweekLastMonth('');
            SetweekFirstDay('');
            SetweekLastDay('');
            const formattedDates = dates.map(dateObj => moment(dateObj.$d).format("YYYY-MM-DD"));
            setSelectedRange(formattedDates);
        }
    };



    const handlePageChange = (page) => {
        setCurrentPage(page);

    };


    const modalTitle = "upload receipt";
    const btn_name = 'Moride';

    const handleOpenModal = (payemnt) => {
        setOpen(!open);
        setSelectedRiderReceipt(payemnt);

    };



    const user_info = useSelector(getUser);

    // fetch receipts
    const allRidersPaymentsReceipts = useSelector(getAllPaginatedRidersReceipts);
    const allRidersPaymentsPaginatedReceipts = useSelector(getAllRidersReceipts);
    // week days
    const [firstDayOfWeek, SetweekFirstDay] = useState('');
    const [lastDayOfWeek, SetweekLastDay] = useState('');

    // month day
    const [firstDayOfMonth, SetweekFirstMonth] = useState('');
    const [lastDayOfMonth, SetweekLastMonth] = useState('');
    const [filterStatus, setfilterStatus] = useState("202");
    const isLoading = useSelector((state) => state.untapped.isLoading);

    const handleSearch = (event) => {
        setsearchQuery(event.target.value)
    };
    const onStatusChange = (event) => {
        setfilterStatus(event.target.value)
    };
    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => {
        if (typeof input === 'string' && option?.label) {
            return option.label.toLowerCase().includes(input.toLowerCase());
        }
        return false; // Return false for non-string inputs or if option.label is missing
    };
    const onSearch = (value) => {
    };

    const handleApproval = async (riderReceipt) => {
        const receipt = {
            "status": 200
        };

        try {

            await axios.post(`https://morideapi.groupeya.com/api/v1/riders/payment/receipt/update/${riderReceipt.id}/`, receipt,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user_info.access}`
                    },
                });
                dispatch(fetchAsyncReceipts({ user_info,currentPage, selectedRange,filterStatus,searchQuery}));
                
            setOpen(!open);
            addToast(`successful approved`, {
                appearance: 'success', autoDismiss: true,
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });

        } catch (error) {

            addToast('something went wrong !', {
                appearance: 'error', autoDismiss: true,
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });



        }
    };

    const handleDenied = async (riderReceipt) => {
        const receipt = {
            "status": 500
        };

        try {

            await axios.post(`https://morideapi.groupeya.com/api/v1/riders/payment/receipt/update/${riderReceipt.id}/`, receipt,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user_info.access}`
                    },
                });
            dispatch(fetchAsyncReceipts({ user_info,currentPage, selectedRange,filterStatus,searchQuery}));
            setOpen(!open);
            addToast(`successful Denied`, {
                appearance: 'success', autoDismiss: true,
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });

        } catch (error) {

            addToast('something went wrong !', {
                appearance: 'error', autoDismiss: true,
                autoDismissTimeout: 5000,
                transitionDuration: 300,
            });



        }
    };

    const getDayOfWeek = () => {
        setIsThisMonth(false);
        const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
        const endDate = moment().endOf('isoWeek').format('YYYY-MM-DD');
        setSelectedRange([startDate, endDate]);
        SetweekFirstDay(startDate);
        SetweekLastDay(endDate);
        SetweekFirstMonth('');
        SetweekLastMonth('');

    }

    const getDayOfMonth = () => {
        setIsThisMonth(true);
        const startDate = moment().startOf('month').format('YYYY-MM-DD');
        const endDate = moment().endOf('month').format('YYYY-MM-DD');
        setSelectedRange([startDate, endDate]);
        SetweekFirstMonth(startDate);
        SetweekLastMonth(endDate);
        SetweekFirstDay('');
        SetweekLastDay('');
    }
    useEffect(() => {
        dispatch(fetchAsyncReceipts({ user_info, currentPage, selectedRange,filterStatus,searchQuery}));
    }, [user_info, selectedRange, currentPage,filterStatus,searchQuery])


    

    return (
        <DashboardLayout>
            <div className='flex justify-center items-center' >
                <div className='bg-white my-4 rounded-lg border container ' >
                    <div className='border-b  flex justify-between items-center   mx-3    px-3 py-4' >
                        <span>Riders receipts</span>
                        <PopModal setOpenModal={open} onChildEvent={handleChildEvent} onChildSaveEvent={handleSubmitAsset} Title={modalTitle} button={btn_name} >
                            <div className='flex receipt-container' >
                                <div className='receipt-image border rounded-sm' >
                                    {riderReceipt.receipt && <img src={`https://morideapi.groupeya.com/${riderReceipt.receipt.receipt}`} alt="Image Icon" className="image" />}
                                </div>
                                <div className='receipt-owner' >
                                    <div className='mb-3 px-2' >
                                        Payer Information
                                    </div>
                                    <div className='flex justify-between  form_control bg-gray-50 p-3 ' >
                                        <label class="block   w-full ">
                                            <span class="block text-sm font-medium text-slate-700">First name</span>

                                            {riderReceipt.rider && <p class="mt-2 text-pink-600 text-sm">
                                                {riderReceipt.rider.first_name}
                                            </p>}
                                        </label>
                                        <label class="block mx-2 w-full ">
                                            <span class="block text-sm font-medium text-slate-700">Last name</span>

                                            {riderReceipt.rider && <p class="mt-2  text-pink-600 text-sm">
                                                {riderReceipt.rider.last_name}
                                            </p>}
                                        </label>

                                    </div>
                                    <div className='form_control flex justify-between  bg-gray-50 p-3 ' >
                                        <label class="block w-1/2 mr-2 ">
                                            <span class="block text-sm font-medium text-slate-700">Rider status</span>

                                            {riderReceipt.rider && <p class="  text-pink-600 text-sm">
                                                {riderReceipt.rider.status}
                                            </p>}
                                        </label>
                                        <label class="block w-1/2">
                                            <span class="block text-sm font-medium text-slate-700">Rider phone number</span>

                                            {riderReceipt.rider && <p class="mt-2  text-pink-600 text-sm">
                                                {riderReceipt.rider.phone_number}
                                            </p>}
                                        </label>
                                    </div>
                                    <div className='form_control flex justify-between  bg-gray-50 p-3 ' >
                                        <label class="block w-1/2 mr-2 ">
                                            <span class="block text-sm font-medium text-slate-700">Amount</span>

                                            {riderReceipt.rider && <p class="  text-pink-600 text-sm">
                                                {riderReceipt.amount}
                                            </p>}
                                        </label>
                                        
                                    </div>
                                </div>
                            </div>

                            {riderReceipt.payment_status && <div className='my-3 py-3 flex justify-between items-center ' >
                                <button onClick={() => handleDenied(riderReceipt)} className='border  rounded-md px-3 py-1 bg-red-500 text-white cursor-pointer' >Deny</button>
                                {riderReceipt.payment_status.id == 202 && <button onClick={() => handleApproval(riderReceipt)} className='border rounded-md px-3 py-1 create_btn' >Approve</button>}
                                {riderReceipt.payment_status.id == 200 && <button className='border rounded-md px-3 py-1 create_btn cursor-not-allowed' disabled >Approved</button>}
                            </div>}
                        </PopModal>
                        <span>
                            <button className='create_btn px-3 py-2 rounded-lg'  >uploaded receipts</button>
                        </span>
                    </div>
                    <div className='px-3 py-3' >
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <div className="flex items-center justify-between pb-4">
                                <div className='mr-3 flex items-center justify-between '>
                                    <div onClick={getDayOfMonth} className={isThisMonth ? 'mx-1 border px-3 py-1 rounded-lg active_filter' : 'mx-1 border px-3 py-1 rounded-lg'}  >
                                        this month
                                    </div>
                                    <div onClick={getDayOfWeek} className={firstDayOfWeek ? 'ml-1 mr-2 border px-3 py-1 rounded-lg active_filter' : 'ml-1 mr-2 border px-3 py-1 rounded-lg'}  >
                                        this week
                                    </div>
                                    <div>
                                        <DatePicker.RangePicker onChange={handleDateRangeChange}
                                            format="YYYY-MM-DD" />
                                    </div>
                                    <div className='mx-2  filter-width border rounded-md flex justify-center items-center' >
                                        <select value={filterStatus} onChange={onStatusChange} >
                                            <option value='200'>Approved</option>
                                            <option value='202'>Pending</option>
                                            <option value='500'>Deny</option>
                                        </select>
                                    </div>
                                </div>
                                <label for="table-search" className="sr-only">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    </div>
                                    <input type="text" id="table-search" value={searchQuery} onChange={handleSearch} className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80  focus:ring-blue-500 focus:border-blue-500  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                                </div>
                            </div>
                            {allRidersPaymentsReceipts.length ? (
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border">
                                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400 border-b">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                riders
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                amount
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                plate
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                phone number
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                paid on
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                payment status
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allRidersPaymentsReceipts.length && (allRidersPaymentsReceipts.map((payemnt, index) => (
                                            <tr className="bg-white border-b dark:hover:bg-gray-300 dark:hover:text-black" key={index} >
                                                <td className="w-4 p-4">
                                                    <div className="flex items-center">
                                                        <span className='pl-1 flex justify-between items-center capitalize'> <span className='mx-1' >{payemnt.rider.first_name}</span> <span>{payemnt.rider.middle_name}</span> <span className='mx-1' >{payemnt.rider.last_name}</span>  </span>
                                                    </div>
                                                </td>
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                    {payemnt.amount ? payemnt.amount : 'N/A'}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {payemnt.rider.plate_number ? payemnt.rider.plate_number : 'N/A'}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {payemnt.rider ? (<span>{payemnt.rider.phone_number}</span>) : ('N/A')}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {payemnt.updated_at ? moment(payemnt.updated_at).format('YYYY-MM-DD , hh:mm') : 'N/A'}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {payemnt.payment_status.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {payemnt.payment_status.id == 202 && <button onClick={() => handleOpenModal(payemnt)} className="bg-yellow-400 text-black  px-3 py-2 rounded-lg cursor-pointer"  >approve</button>}
                                                    {payemnt.payment_status.id == 200 && <button onClick={() => handleOpenModal(payemnt)} className="create_btn px-3 py-2 rounded-lg cursor-pointer"  >approved</button>}
                                                    {payemnt.payment_status.id == 500 && <button onClick={() => handleOpenModal(payemnt)} className="bg-red-500 text-white px-3 py-2 rounded-lg cursor-pointer"  >Deny</button>}
                                                </td>
                                            </tr>)))
                                        }
                                    </tbody>
                                </table>) : (isLoading ? (<div role="status" className='flex justify-center my-5' >
                                    <svg aria-hidden="true" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>) : (<div className='flex justify-center m-5 p-4'>
                                    No matching receipts
                                </div>))}

                        </div>
                        <div className='flex justify-end my-3' >
                            <Pagination defaultCurrent={1} total={allRidersPaymentsPaginatedReceipts.count} onChange={handlePageChange} className="border p-3 rounded-lg" />
                        </div>

                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default PaymentsReceipts
